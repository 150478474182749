import { CircularProgress, Dialog, DialogContent, Grid } from '@mui/material';
import React from 'react';

function Loader({ loading }) {
  return (
    <Dialog
      open={loading}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress style={{ color: '#fff' }} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Loader;
