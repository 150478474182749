import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AnnouncementForm from './AnnouncementForm';
import { getAnnouncementByClinicId, deleteAnnouncement } from 'app/api-services/announcement-ws';
import useAuth from 'app/hooks/useAuth';
import AnnouncementActionButton from './AnnouncementActionDialog';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600,
  overflowX: 'auto',
});

const AnnouncementTable = () => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAuth();
  const authToken = user?.token;

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  function showSnackBar() {
    //   function showSnackBar = (message, type) => {
    // setSnackBarMessage(message);
    // setSnackBarType(type);
    // setOpen(true);
  }

  function handleClose(_, reason) {
    if (reason === 'clickaway') return;
    setOpen(false);
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAnnouncements = () => {
    getAnnouncementByClinicId(user?._id, user?.authToken)
      .then((res) => {
        console.log(res);
        setAnnouncements(res.data.data);
      })
      .catch((err) => {
        // showSnackBar('Error loading announcements', 'error');
        // setSnackBarType('error');
        // showSnackBar();
      });
  };

  const handleDeleteAnnouncement = (announcementId) => {
    deleteAnnouncement(announcementId, user?.authToken)
      .then((res) => {
        console.log(res);
        fetchAnnouncements();
        // showSnackBar('Announcement deleted successfully', 'success');
      })
      .catch((err) => {
        // showSnackBar('Error deleting announcement', 'error');
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  console.log(announcements);

  return (
    <Grid container spacing={2} style={{ marginTop: '10px', padding: '10px' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12} sm={6} md={6} lg={9}>
        <Card style={{ padding: '10px' }}>
          <CardContent>
            <Typography variant="h6">Announcements</Typography>
            <StyledButton variant="contained" color="primary" onClick={handleOpenDialog}>
              <Icon className="icon">add_circle</Icon>Add Announcement{''}
            </StyledButton>
            <Dialog onClose={handleCloseDialog} open={openDialog}>
              <DialogTitle>Add Announcement</DialogTitle>
              <DialogContent>
                <AnnouncementForm
                  handleCloseDialog={handleCloseDialog}
                  fetchAnnouncements={fetchAnnouncements}
                />
              </DialogContent>
            </Dialog>
            <Grid>
              <Box width="100%" overflow="auto">
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>Type</TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>Duration</TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {announcements
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((announcement, index) => (
                        <TableRow
                          key={index}
                          style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                        >
                          <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                          <TableCell align="left">{announcement.title}</TableCell>
                          <TableCell align="left">{announcement.description}</TableCell>
                          <TableCell align={isXsScreen ? 'left' : 'center'}>
                            {announcement.type}
                          </TableCell>
                          <TableCell align={isXsScreen ? 'left' : 'center'}>
                            {announcement.duration}
                          </TableCell>
                          <TableCell align="center">
                            <AnnouncementActionButton
                              clinicId={user?._id}
                              handleDeleteAnnouncement={handleDeleteAnnouncement}
                              announcement={announcement}
                              fetchAnnouncements={fetchAnnouncements}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={'Records per page'}
                  count={announcements.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnnouncementTable;
