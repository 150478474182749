import Typography from '@mui/material/Typography';
import { Alert, Autocomplete, Box, Button, Grid, Snackbar, styled } from '@mui/material';
import { Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addAccounts, updateAccounts } from 'app/api-services/accounts-ws';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const AccountsForm = ({
  token,
  accounts,
  clinicId,
  handleCloseDialog,
  fetchAccountsByClinicId,
}) => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(accounts?.category);
  const [name, setName] = useState(accounts?.name);
  const [amount, setAmount] = useState(accounts?.amount);
  const [date, setDate] = useState(accounts?.date);
  const [productName, setProductName] = useState(accounts?.product_name);
  const [quantity, setQuantity] = useState(accounts?.quantity);
  const [supplierName, setSupplierName] = useState(accounts?.supplier_name);
  const [file, setFile] = useState(accounts?.pdffiles);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');

  const categoryList = [
    { name: 'Salary', value: 'SALARY' },
    { name: 'Others', value: 'OTHERS' },
  ];
  const addAccountsRequest = {
    refClinicId: clinicId,
    category: category,
    name: name,
    amount: amount,
    date: date,
    product_name: productName,
    quantity: quantity,
    supplier_name: supplierName,
    pdffiles: file,
  };

  const addhandleSubmit = (event) => {
    addAccounts(addAccountsRequest, token)
      .then((res) => {
        if (res.data.status === 'success') {
          setSnackBarMessage('Accounts Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Accounts');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Accounts');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const updateAccountsRequest = {
    _id: accounts?._id,
    refClinicId: clinicId,
    category: category,
    name: name,
    amount: amount,
    date: date,
    product_name: productName,
    quantity: quantity,
    supplier_name: supplierName,
    pdffiles: file,
  };
  const updatehandleSubmit = (event) => {
    updateAccounts(updateAccountsRequest, token)
      .then((res) => {
        if (res.data.status === 'success') {
          setSnackBarMessage('Accounts Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Accounts');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Accounts');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setFile(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };
  console.log('aa', accounts);
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Accounts"
          src={`data:image/jpeg;base64,${file}`}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Image
          </Button>
        </label>
      </SimpleCard>
      <Box py="12px" />

      <ValidatorForm
        onSubmit={accounts ? updatehandleSubmit : addhandleSubmit}
        onError={() => null}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Autocomplete
              id="tags-outlined"
              options={categoryList}
              value={categoryList?.find((r) => r.value == category)}
              onChange={(_, val) => {
                setCategory(val.value);
              }}
              getOptionLabel={(option) => option.name}
              // defaultValue={[top100Films[13]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Category"
                  placeholder="Choose Category"
                  fullWidth
                  error={!category}
                  helperText={!category ? 'This field is required' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Item> */}{' '}
            <TextField
              type="text"
              name="name"
              label="Name"
              onChange={(val) => {
                setName(val.target.value);
              }}
              value={name || ''}
              validators={['required']}
              errorMessages={['this field is required']}
            />
            {/* </Item> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="amount"
              label="Amount"
              value={amount || ''}
              onChange={(val) => {
                setAmount(val.target.value);
              }}
              validators={['required', 'isNumber']}
              errorMessages={['this field is required', 'It should be a number']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              name="date"
              value={date || ''}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(val) => {
                setDate(val.target.value);
              }}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="Product Name"
              onChange={(val) => {
                setProductName(val.target.value);
              }}
              label="Product Name"
              value={productName || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="quantity"
              onChange={(val) => {
                setQuantity(val.target.value);
              }}
              label="Quantity"
              value={quantity || ''}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            <TextField
              type="text"
              name="supplier Name"
              onChange={(val) => {
                setSupplierName(val.target.value);
              }}
              label="supplier Name"
              value={supplierName || ''}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {accounts ? 'Save Changes' : 'Add Accounts'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AccountsForm;
