import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Span } from 'app/components/Typography';
import { ValidatorForm } from 'react-material-ui-form-validator';
import useAuth from 'app/hooks/useAuth';
import { addAnnouncement, updateAnnouncement } from 'app/api-services/announcement-ws';

const AnnouncementForm = ({ announcement, handleCloseDialog, fetchAnnouncements }) => {
  const [title, setTitle] = useState(announcement?.title);
  const [description, setDescription] = useState(announcement?.description);
  const [type, setType] = useState(announcement?.type || '');
  const [duration, setDuration] = useState(announcement?.duration?.split('T')[0]);
  const { user } = useAuth();
  const authToken = user?.token;

  const announcementTypes = [
    { value: 'Warning', label: 'Warning' },
    { value: 'Urgent', label: 'Urgent' },
    { value: 'Event', label: 'Event' },
    { value: 'Information', label: 'Information' },
    { value: 'Reminder', label: 'Reminder' },
    { value: 'Promotion', label: 'Promotion' },
    { value: 'Policy Update', label: 'Policy Update' },
    { value: 'Holiday Notice', label: 'Holiday Notice' },
  ];

  const announcementRequest = {
    refClinicId: user?._id,
    title,
    description,
    type,
    duration,
  };

  const handleAddAnnouncement = () => {
    addAnnouncement(announcementRequest, user?.authToken)
      .then((res) => {
        handleCloseDialog();
        fetchAnnouncements();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };

  const handleUpdateAnnouncement = () => {
    const updateannouncementRequest = {
      id: announcement?._id,
      refClinicId: user?._id,
      title,
      description,
      type,
      duration,
    };
    updateAnnouncement(updateannouncementRequest, user?.authToken)
      .then((res) => {
        handleCloseDialog();
        fetchAnnouncements();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };

  const handleSubmit = () => {
    if (announcement) {
      handleUpdateAnnouncement();
    } else {
      handleAddAnnouncement();
    }
  };

  return (
    <div>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(err) => {
          console.log(err);
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="title"
              label="Title"
              value={title || ''}
              onChange={(val) => {
                setTitle(val.target.value);
              }}
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              label="Description"
              value={description || ''}
              onChange={(val) => {
                setDescription(val.target.value);
              }}
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={type}
                onChange={(val) => {
                  setType(val.target.value);
                }}
                validators={['required']}
                errorMessages={['This field is required']}
              >
                {announcementTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              name="duration"
              label="Duration"
              InputLabelProps={{
                shrink: true,
              }}
              value={duration || ''}
              onChange={(val) => {
                setDuration(val.target.value);
              }}
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>

          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {announcement ? 'Save Announcement' : 'Add Announcement'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AnnouncementForm;
