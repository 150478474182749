import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './reducers/RootReducer';

// const initialState = {};
// const middlewares = [thunk];
// let devtools = (x) => x;

// if (
//   process &&
//   process.env.NODE_ENV !== 'production' &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }

export const Store = createStore(
  RootReducer
  // compose(applyMiddleware(...middlewares), devtools)
);
