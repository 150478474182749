import { SimpleCard } from 'app/components';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Icon,
  Snackbar,
  Grid,
  Box,
  Card,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import DoctorTable from './Tables/DoctorTable';
import Dialog from '@mui/material/Dialog';
import { getDoctorDetails } from 'app/api-services/doctor-ws';
import DoctorForm from './Forms/DoctorForm';
import useAuth from 'app/hooks/useAuth';
import Norecord from 'app/components/norecord';
import DoctorSearchableDate from './doctorSearchBar';
import moment from 'moment';
import Loader from 'app/components/Loader';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const DoctorList = () => {
  const [doctorsList, setDoctorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();
  const [profileImage, setProfileImage] = useState();
  const [filteredData, setFilteredData] = useState(null);
  const [phoneFilter, setPhoneFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState(null);

  const filterDoctorList = (nameValue) => {
    const filtered = doctorsList.filter(
      (item) =>
        !nameValue ||
        (
          item.doctorName.firstName?.toString() +
          ' ' +
          item.doctorName.lastName?.toString()
        ).includes(nameValue)
      // (!phoneValue || item.doctorPhone.toString().includes(phoneValue))
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

  const fetchDoctorDetails = () => {
    getDoctorDetails(user?._id, user?.authToken)
      .then((res) => {
        setDoctorsList(res.data.data.reverse());
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
        setSnackBarMessage('Error in Loading Doctor Details');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    fetchDoctorDetails();
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));

  return (
    <div>
      <Loader loading={isLoading} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon>Add Doctor
        </StyledButton>

        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
          <DialogTitle>Add New Doctor</DialogTitle>
          <DialogContent>
            <DoctorForm
              clinicId={user?._id}
              token={user?.authToken}
              handleCloseDialog={handleCloseDialog}
              setOpenDialog={setOpenDialog}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
            />
          </DialogContent>
        </Dialog>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} sm={6}>
            <StyledCard elevation={6} sx={{ marginBottom: '20px' }}>
              <ContentBox>
                <Box ml="12px">
                  {/* Use the DoctorListDropdown component */}
                  <DoctorSearchableDate
                    setNameFilter={setNameFilter}
                    filterDoctorList={filterDoctorList}
                    doctorList={doctorsList}
                  />
                </Box>
              </ContentBox>
            </StyledCard>
          </Grid>
        </Grid>

        {doctorsList.length === 0 ? (
          <SimpleCard>
            <Norecord />
          </SimpleCard>
        ) : (
          <SimpleCard title="Doctor Details">
            <DoctorTable
              token={user?.authToken}
              fetchDoctorDetails={fetchDoctorDetails}
              doctorsList={filteredData ? filteredData : doctorsList}
            />
          </SimpleCard>
        )}
      </Container>
    </div>
  );
};
export default DoctorList;
