import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getAllAnnouncements = (authToken) => {
  const urlPath = '/announcement/getAllAnnouncements';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    Authorization: `Bearer ${authToken}`,
  });
};

export const getAnnouncementByClinicId = (clinicId, authToken) => {
  const urlPath = `/announcement/getAnnouncementByClinicId/${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const getAnnouncementById = (announcementId, authToken) => {
  const urlPath = `/announcement/getAnnouncementById/${announcementId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const addAnnouncement = (announcementRequest, authToken) => {
  const urlPath = '/announcement/addAnnouncement';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: announcementRequest,
    additionalHeader: additionalHeader,
  });
};

export const deleteAnnouncement = (announcementId, authToken) => {
  const urlPath = `/announcement/deleteAnnouncement/${announcementId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const updateAnnouncement = (announcementRequest, authToken) => {
  const urlPath = '/announcement/updateAnnouncement';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: announcementRequest,
    additionalHeader: additionalHeader,
  });
};
