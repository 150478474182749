import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getDoctorDetails = (clinicId, authToken) => {
  const urlPath = `/doctor/getDoctorsByRefClinicId/${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};
export const getSelectedDoctorDetails = (doctorId, authToken) => {
  const urlPath = `/doctor/getSelectedDoctorDetails/${doctorId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const addDoctor = (doctorRequest, authToken) => {
  const urlPath = '/doctor/addDoctor';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: doctorRequest,
    additionalHeader: additionalHeader,
  });
};

export const removeDoctor = (doctorId, authToken) => {
  const urlPath = `/doctor/removeDoctorDetails/:${doctorId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const updateDoctorDetails = (doctorRequest, authToken) => {
  const urlPath = '/doctor/updateDoctor';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: doctorRequest,
    additionalHeader: additionalHeader,
  });
};

export const doctorOnboardingMail = (mailRequest, authToken) => {
  const urlPath = '/doctor/doctorOnboardingMail';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: mailRequest,
    additionalHeader: additionalHeader,
  });
};
