import React, { useState } from 'react';
import { SimpleCard } from 'app/components';
import {
  Card,
  Grid,
  styled,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { Fragment } from 'react';
import Campaigns from './shared/Campaigns';
import DoughnutChart from './shared/Doughnut';
import RowCards from './shared/RowCards';
import StatCards from './shared/StatCards';
import StatCards2 from './shared/StatCards2';
import TopSellingTable from './shared/TopSellingTable';
import UpgradeCard from './shared/UpgradeCard';
import TermsForm from './Forms/TermsForm';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
const AdminDashboard = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StatCards />
            <TopSellingTable />
            <StatCards2 />
            <H4>Ongoing Projects</H4>
            <RowCards />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Traffic Sources</Title>
              <SubTitle>Last 30 days</SubTitle>

              <DoughnutChart
                height="300px"
                color={[palette.primary.dark, palette.primary.main, palette.primary.light]}
              />
            </Card>

            <UpgradeCard />
            <Campaigns />
            <SimpleCard>
              <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                T and C
              </StyledButton>
              <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <DialogTitle>Terms and Conditions Form</DialogTitle>
                <DialogContent>
                  <TermsForm handleCloseDialog={handleCloseDialog} />
                </DialogContent>
              </Dialog>
            </SimpleCard>
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default AdminDashboard;
