import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const checkAuthStatus = () => {
  const urlPath = '/whatsapp/checkAuth';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
  });
};

export const generateQR = () => {
  const urlPath = '/whatsapp/getQr';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
  });
};

export const sendMessage = (messageRequest) => {
  const urlPath = '/whatsapp/sendText';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: messageRequest,
  });
};

export const logoutWhatsApp = () => {
  const urlPath = '/whatsapp/logoutWhatsApp';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
  });
};

export const sendImage = (messageRequest) => {
  const urlPath = '/whatsapp/sendImage';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: messageRequest,
  });
};

export const sendPdf = (messageRequest) => {
  const urlPath = '/whatsapp/sendPdf';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: messageRequest,
  });
};
