import React, { useState } from 'react';
import {
  Alert,
  Snackbar,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomizedDialogs from '../models/CpatientDialog';
import StatusDialog from '../shared/statusDialog';
import { updatePatientStatus } from 'app/api-services/patient-ws';
import moment from 'moment';
import { getAllPatientReport, uploadPatientReport } from 'app/api-services/patientReport-ws';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});

const CpatientTable = ({ patientList, fetchPatientDetails, token }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [alreadyUploadedImages, setAlreadyUploadedImages] = useState([]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const postPatientStatus = (updatePatientRequest) => {
    console.log('in submit');
    updatePatientStatus(updatePatientRequest, token)
      .then((res) => {
        if (res.data.status === 'success') {
          setSnackBarMessage('Patient Status Updated Successfully');
          setSnackBarType('success');
          showSnackBar();
        } else {
          setSnackBarMessage('Error in Updating Patient Status');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in updating Patient Status');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const whatsappSuccessMsg = () => {
    setSnackBarMessage('Reciept sent successfully to patient');
    setSnackBarType('success');
    showSnackBar();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function showSnackBar() {
    setOpen(true);
  }

  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const uploadPatientReports = (patientReportRequest) => {
    uploadPatientReport(patientReportRequest[0], token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAlreadyUploadedImages = async (patientReportFolder) => {
    getAllPatientReport(patientReportFolder, token)
      .then((res) => {
        setAlreadyUploadedImages(res.data.data);
      })
      .catch((err) => {
        setAlreadyUploadedImages([]);
      });
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Box width="100%" overflow="auto">
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align={isXsScreen ? 'left' : 'center'}>Patient Id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Referred Date</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>Referred Doctor</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>Status</TableCell>
              <TableCell align={isXsScreen ? 'left' : 'center'}>Patient Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((patient, index) => (
                <TableRow
                  key={index}
                  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                >
                  <TableCell align={isXsScreen ? 'left' : 'center'}>{patient?.patientId}</TableCell>
                  <TableCell align="left">
                    {patient?.patientName?.firstName?.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                    {patient?.patientName?.lastName?.replace(/^\w/, (c) => c.toUpperCase())}
                  </TableCell>

                  <TableCell align="center">{(patient?.createdDate).split('T')[0]}</TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>
                    {patient?.refDoctorName}
                  </TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>
                    <StatusDialog
                      postPatientStatus={postPatientStatus}
                      showSnackBar={showSnackBar}
                      setSnackBarType={setSnackBarType}
                      setSnackBarMessage={setSnackBarMessage}
                      patientId={patient?._id}
                      patient={patient}
                      token={token}
                      pdfFile={patient?.pdf3Dreport}
                      patientStatus={patient?.status}
                      uploadPatientReport={uploadPatientReports}
                      alreadyUploadedImages={alreadyUploadedImages}
                      fetchAlreadyUploadedImages={fetchAlreadyUploadedImages}
                    />
                  </TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>
                    <CustomizedDialogs
                      whatsappSuccessMsg={whatsappSuccessMsg}
                      token={token}
                      fetchPatientDetails={fetchPatientDetails}
                      patient={patient}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>

        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={'Records per page'}
          count={patientList.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        />
      </Box>
    </div>
  );
};

export default CpatientTable;
