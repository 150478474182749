import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';

const AgreementDialog = ({ setAgreeChecked, agreeChecked, updateTC }) => {
  const [open, setOpen] = useState(true);

  const handleAgreeChange = (event) => {
    setAgreeChecked(event.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAgreeChecked(false);
  };

  const handleSubmit = () => {
    console.log('Form submitted!');
    // Close the dialog after submit button is clicked
    updateTC();
    handleClose();
  };

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
                Open Terms and Conditions
            </Button> */}
      <Dialog open={open} maxWidth="md" maxHeight="140vh">
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Terms of Service
            <br />
            1. Use of Services
            <br />
            1.1 You agree to use the Services solely for lawful purposes and in compliance with all
            applicable laws and regulations.
            <br />
            1.2 You agree not to use the Services in any manner that could harm, damage, disable, or
            impair the Website or interfere with any other party's use of the Services.
            <br />
            2. Data Security
            <br />
            2.1 Refease takes reasonable measures to ensure the security and confidentiality of data
            transmitted through the Website and Services.
            <br />
            2.2 While Refease implements industry-standard security protocols, you acknowledge that
            no data transmission over the internet can be guaranteed to be completely secure.
            <br />
            2.3 You agree that Refease shall not be liable for any unauthorized access to or
            disclosure of your data, unless such access or disclosure is due to Refease's gross
            negligence or willful misconduct.
            <br />
            3. Intellectual Property
            <br />
            3.1 All content, trademarks, logos, and other intellectual property displayed on the
            Website are the property of Refease or its licensors and are protected by applicable
            intellectual property laws.
            <br />
            3.2 You agree not to reproduce, modify, distribute, or otherwise use any content from
            the Website without the prior written consent of Refease.
            <br />
            4. Limitation of Liability
            <br />
            4.1 To the fullest extent permitted by law, Refease shall not be liable for any direct,
            indirect, incidental, special, consequential, or exemplary damages, including but not
            limited to damages for loss of profits, goodwill, use, data, or other intangible losses,
            resulting from:
            <br />
            (a) the use or inability to use the Services;
            <br />
            (b) unauthorized access to or alteration of your data;
            <br />
            (c) any other matter relating to the Services.
            <br />
            5. Indemnification
            <br />
            5.1 You agree to indemnify and hold Refease harmless from and against any claims,
            damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising
            out of or related to your use of the Services or any violation of these Terms.
            <br />
            6. Governing Law
            <br />
            6.1 These Terms shall be governed by and construed in accordance with the laws of [Your
            Jurisdiction], without regard to its conflict of law provisions.
            <br />
            7. Changes to Terms
            <br />
            7.1 Refease reserves the right to modify or update these Terms at any time without prior
            notice. Your continued use of the Website and Services after any such changes
            constitutes your acceptance of the new Terms.
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox checked={agreeChecked} onChange={handleAgreeChange} />}
            label="I agree to the terms and conditions"
          />
          <div style={{ margin: '16px', textAlign: 'right' }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!agreeChecked}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AgreementDialog;
