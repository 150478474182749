import { SimpleCard } from 'app/components';
import React, { useEffect, useState } from 'react';
import { styled, Grid } from '@mui/material';
import DoctorReportTable from '../dashboard/Tables/DoctorReportTable';
import { getPatientsByClinicIdFilterDate } from 'app/api-services/patient-ws';

import useAuth from 'app/hooks/useAuth';
import DateFilter from './DateFilter';
import moment from 'moment';
import dayjs from 'dayjs';

const DoctorList = () => {
  const today = new Date().toISOString().split('T')[0];
  const [isLoading, setIsLoading] = useState(true);
  const [reportList, setReportList] = useState([]);
  const { user } = useAuth();

  const [fromDate, setFromDate] = useState(
    dayjs(moment(today).subtract(30, 'days').format('YYYY-MM-DD'))
  );
  const [toDate, setToDate] = useState(dayjs(moment(today).format('YYYY-MM-DD')));
  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));

  useEffect(() => {
    fetchReportsByDate();
  }, []);

  const fetchReportsByDate = () => {
    setIsLoading(true); // Set loading state before fetching data
    getPatientsByClinicIdFilterDate(user?._id, fromDate, toDate, user?.authToken)
      .then((res) => {
        console.log(res);
        setReportList(res.data.data.reverse());
        setIsLoading(false); // Reset loading state after data is fetched
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false); // Reset loading state if an error occurs
      });
  };

  return (
    <Container>
      <Grid xs={12} style={{ marginBottom: '30px' }}>
        <SimpleCard>
          <DateFilter
            fetchReportsByDate={fetchReportsByDate}
            fromDate={fromDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            toDate={toDate}
          />
        </SimpleCard>
      </Grid>

      <SimpleCard title="Reports">
        {isLoading ? <p>Loading...</p> : <DoctorReportTable reportList={reportList} />}
      </SimpleCard>
    </Container>
  );
};

export default DoctorList;
