import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ImageOptionForm from './ImageOptionForm';
import { getimageOptionDetails, removeAddedImage } from 'app/api-services/image-ws';
import useAuth from 'app/hooks/useAuth';

import ActionButton from './ActionButton';
import Loader from 'app/components/Loader';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});
const ImageOptionTable = () => {
  const [open, setOpen] = useState(false);
  const [open2DDialog, setOpen2DDialog] = useState(false);
  const [open3DDialog, setOpen3DDialog] = useState(false);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [image2DList, setImage2DList] = useState([]);

  const [image3DList, setImage3DList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');

  const { user } = useAuth();
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fetchDeleteImage = (removeImageRequest) => {
    setIsLoading(true);
    removeAddedImage(removeImageRequest, user?.authToken)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchImageOptions();
  }, []);
  const fetchImageOptions = () => {
    setIsLoading(true);
    getimageOptionDetails(user?._id, user?.authToken)
      .then((res) => {
        setIsLoading(false);
        setImage2DList(res.data[0].digitalImages2D);
        setImage3DList(res.data[0].digitalImages3D);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in Loading Image List');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const handleClose2DDialog = () => {
    setOpen2DDialog(false);
  };
  const handleOpen2DDialog = () => {
    // fetchDoctorDetails(clinicId);
    setOpen2DDialog(true);
  };
  const handleClose3DDialog = () => {
    setOpen3DDialog(false);
  };
  const handleOpen3DDialog = () => {
    // fetchDoctorDetails(clinicId);
    setOpen3DDialog(true);
  };
  return (
    <>
      <Loader loading={isLoading} />
      <Grid container spacing={2} style={{ marginTop: '10px', padding: '10px' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={snackBarType}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
        <Grid item xs={12} sm={6} md={9} lg={9}>
          <Card style={{ padding: '10px' }}>
            <CardContent>
              <Typography variant="h6">Image Option Details</Typography>
              <StyledButton variant="contained" color="primary" onClick={handleOpen2DDialog}>
                <Icon className="icon">group_add</Icon>Add 2D Image Service{' '}
              </StyledButton>
              <Dialog onClose={handleClose2DDialog} open={open2DDialog}>
                <DialogTitle>Add 2D Image</DialogTitle>
                <DialogContent>
                  <ImageOptionForm
                    handleCloseDialog={handleClose2DDialog}
                    fetchImageOptions={fetchImageOptions}
                    is2D={true}
                  />
                </DialogContent>
              </Dialog>
              <Grid>
                <Box width="100%" overflow="auto">
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
                        <TableCell align="left">Image Name</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>SMS Name</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Service Cost</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {image2DList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((image, index) => (
                          <TableRow
                            key={index}
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                          >
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {image?.name.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                            </TableCell>
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {image?.sms}
                            </TableCell>
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {image?.serviceCost}
                            </TableCell>
                            <TableCell align="center">
                              <ActionButton
                                clinicId={user?._id}
                                fetchDeleteImage={fetchDeleteImage}
                                is2D={true}
                                fetchImageOptions={fetchImageOptions}
                                image={image}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </StyledTable>

                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={'Records per page'}
                    count={image2DList?.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  />
                </Box>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ padding: '10px' }}>
            <CardContent>
              <Typography variant="h6">Image Option Details</Typography>
              <StyledButton variant="contained" color="primary" onClick={handleOpen3DDialog}>
                <Icon className="icon">group_add</Icon>Add 3D Image Service{' '}
              </StyledButton>
              <Dialog onClose={handleClose3DDialog} open={open3DDialog}>
                <DialogTitle>Add 3D Image</DialogTitle>
                <DialogContent>
                  <ImageOptionForm
                    fetchImageOptions={fetchImageOptions}
                    handleCloseDialog={handleClose3DDialog}
                    is2D={false}
                  />
                </DialogContent>
              </Dialog>
              <Grid>
                <Box width="100%" overflow="auto">
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
                        <TableCell align="left">Image Name</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>SMS Name</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Service Cost</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {image3DList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((image, index) => (
                          <TableRow
                            key={index}
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                          >
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {image?.name.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                            </TableCell>
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {image?.sms}
                            </TableCell>
                            <TableCell align={isXsScreen ? 'left' : 'center'}>
                              {image?.serviceCost}
                            </TableCell>
                            <TableCell align="center">
                              <ActionButton
                                is2D={false}
                                clinicId={user?._id}
                                fetchDeleteImage={fetchDeleteImage}
                                image={image}
                                fetchImageOptions={fetchImageOptions}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </StyledTable>
                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={'Records per page'}
                    count={image3DList?.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  />
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ImageOptionTable;
