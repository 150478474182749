import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import ClinicPatientList from './CPatientList';
import DoctorPatientList from './DPatientList';
import ClinicList from './ClinicList';
import DoctorList from './DoctorList';
import AboutCLinic from './AboutClinic';
import DoctorReport from './DoctorReport';
import AdminDashboard from './Adashboard';
import DoctorDashboard from './Ddashboard';
import ClinicDashboard from './Cdashboard';
import AdminList from './AdminList';
import ClinicServices from './services/ClinicServices';
import ClinicAnnouncement from './announcement/ClinicAnnouncement';
import Accounts from '../accounts/Accounts';
import WhatsApp from '../whatsapp/whatsapp';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: '/dashboard/default', element: <Analytics />, auth: authRoles.admin },
  { path: '/dashboard/admin', element: <AdminDashboard />, auth: authRoles.admin },
  { path: '/dashboard/doctor', element: <DoctorDashboard />, auth: authRoles.doctor },
  { path: '/dashboard/clinic', element: <ClinicDashboard />, auth: authRoles.clinic },
  { path: '/admin/clinicList', element: <ClinicList />, auth: authRoles.admin },
  { path: '/clinic/patientList', element: <ClinicPatientList />, auth: authRoles.clinic },
  { path: '/clinic/doctorList', element: <DoctorList />, auth: authRoles.clinic },
  { path: '/clinic/accounts', element: <Accounts />, auth: authRoles.clinic },
  { path: '/doctor/patientList', element: <DoctorPatientList />, auth: authRoles.doctor },
  { path: '/doctor/aboutClinic', element: <AboutCLinic />, auth: authRoles.doctor },
  { path: '/clinic/doctorreport', element: <DoctorReport />, auth: authRoles.clinic },
  { path: '/clinic/services', element: <ClinicServices />, auth: authRoles.clinic },
  { path: '/clinic/announcement', element: <ClinicAnnouncement />, auth: authRoles.clinic },
  { path: '/clinic/whatsapp', element: <WhatsApp />, auth: authRoles.clinic },
  { path: '/admin/adminList', element: <AdminList />, auth: authRoles.admin },
];

export default dashboardRoutes;
