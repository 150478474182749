import React from 'react';
import ImageOptionTable from './ImageOptionTable';

function ClinicServices() {
  const handleSubmit = (formData) => {
    console.log(formData.get('image2D'));
    console.log(formData.get('image3D'));
  };

  return (
    <div>
      <ImageOptionTable />
    </div>
  );
}

export default ClinicServices;
