import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getAllClinicDetails = (authToken) => {
  const urlPath = '/clinic/getAllClinicDetails';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getClinicDetails = (clinicId, authToken) => {
  const urlPath = `/clinic/getClinicDetails/${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const addClinic = (clinicRequest, authToken) => {
  const urlPath = '/clinic/addClinic';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: clinicRequest,
    additionalHeader: additionalHeader,
  });
};

export const removeClinic = (clinicId, authToken) => {
  const urlPath = `/clinic/removeClinicDetails/:${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const updateClinicDetails = (clinicRequest, authToken) => {
  const urlPath = '/clinic/updateClinic';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: clinicRequest,
    additionalHeader: additionalHeader,
  });
};
