import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAdmin = (adminRequest, authToken) => {
  const urlPath = '/admin/addAdmin';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: adminRequest,
    additionalHeader: additionalHeader,
  });
};

export const updateAdminDetails = (adminRequest, authToken) => {
  const urlPath = '/admin/updateAdmin';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: adminRequest,
    additionalHeader: additionalHeader,
  });
};

export const getAllAdminDetails = (authToken) => {
  const urlPath = '/admin/getAllAdminDetails';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getSelectedAdminDetails = (adminId, authToken) => {
  const urlPath = `/admin/getadminDetails/${adminId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
