import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getimageOptionDetails = (clinicId, authToken) => {
  const urlPath = `/imageOption/getimageOptionDetails?clinicId=${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const addimageOption = (imageRequest, authToken) => {
  const urlPath = '/imageOption/addimageOption';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};

export const addImageToList = (imageRequest, authToken) => {
  const urlPath = '/imageOption/updateImageOptionsNewList';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};

export const removeAddedImage = (imageRequest, authToken) => {
  const urlPath = '/imageOption/removeParticularService';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};

export const getImageOptionsList = (doctorId, authToken) => {
  const urlPath = '/imageOption/getImageOptionsList/';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const updateImageOptions = (imageRequest, authToken) => {
  const urlPath = '/imageOption/updateImageOptions';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};

export const edit2DImageOption = (imageRequest, authToken) => {
  const urlPath = `/imageOption/update2DImageOptionsList/${imageRequest?.clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};

export const edit3DImageOption = (imageRequest, authToken) => {
  const urlPath = `/imageOption/update3DImageOptionsList/${imageRequest?.clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: imageRequest,
    additionalHeader: additionalHeader,
  });
};
