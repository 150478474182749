import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const uploadPatientReport = (patientReportRequest, authToken) => {
  const urlPath = '/patientReports/uploadFile';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: patientReportRequest,
    additionalHeader: additionalHeader,
  });
};

export const getPatientReport = (reportFile, authToken) => {
  const urlPath = `/patientReports/getFiles?key=${reportFile}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getAllPatientReport = (reportFolder, authToken) => {
  const urlPath = `/patientReports/getAllFiles?key=${reportFolder}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const deletePatientReport = (reportFile, authToken) => {
  const urlPath = `/patientReports/deleteFile?key=${reportFile}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
