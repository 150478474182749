import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
// import SearchIcon from '@material-ui/icons/Search';

const DoctorSearchableDate = ({ filterDoctorList, doctorList }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = doctorList.filter((doctor) =>
      (doctor?.doctorName?.firstName + ' ' + doctor?.doctorName?.lastName)?.toString()?.includes(value)
    );
    setFilteredDoctors(filtered);
  };

  const handleFilterSubmit = () => {
    filterDoctorList(inputValue);
  };

  const handleItemClick = (selectedDoctor) => {
    setInputValue(selectedDoctor.doctorName.firstName + ' ' + selectedDoctor.doctorName.lastName);
    closeDropdown();
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      closeDropdown();
    }
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            type="text"
            value={inputValue}
            placeholder="doctorName"
            onFocus={openDropdown}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>
      {isOpen && (
        <div className="dropdown-menu">
          {filteredDoctors.map((doctor) => (
            <div
              key={doctor._id}
              onClick={() => handleItemClick(doctor)}
              className={doctor.doctorName.firstName === inputValue ? 'active' : ''}
            >
              {doctor.doctorName.firstName} {doctor.doctorName.lastName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DoctorSearchableDate;
