export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const getBaseUrl = async () => {
  return 'https://newproject1-otz6.onrender.com/api/';
  // return 'http://localhost:3000/api/';
};
