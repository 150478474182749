import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import PatientForm from '../Forms/PatientForm';
import { Box, Menu, MenuItem, ListItemIcon } from '@mui/material';
import PDFComponent from './PDFComponent';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { removePatient } from 'app/api-services/patient-ws';
import useAuth from 'app/hooks/useAuth';
const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const CustomizedDialogs = ({ fetchPatientDetails, patient, token, whatsappSuccessMsg }) => {
  const today = new Date().toISOString().split('T')[0];
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdfDialog, setPdfDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { user } = useAuth();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handlePdfClick = () => {
    setOpen(false); // Close any other dialogs before opening PDF dialog
    setAnchorEl(null); // Close menu
    // Open PDF dialog
    setPdfDialog(true);
  };

  const handleDeleteClick = () => {
    setOpen(false);
    setAnchorEl(null); // Close menu
    setDeleteDialog(true);
  };

  const handleDeletePatient = () => {
    removePatient(patient?._id, user?.authToken)
      .then((res) => {
        setDeleteDialog(false);
        fetchPatientDetails(
          moment(today).subtract(30, 'days').format('YYYY-MM-DD'),
          moment(today).format('YYYY-MM-DD')
        );
      })
      .catch((err) => {
        setDeleteDialog(false);
      });
  };

  const handlePdfDialogClose = () => {
    setPdfDialog(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClick}>
        Actions
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditClick} style={{ color: 'primary' }}>
          <ListItemIcon>
            <EditIcon color="primary" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={handlePdfClick} style={{ color: 'primary' }}>
          <ListItemIcon>
            <DownloadIcon color="primary" />
          </ListItemIcon>
          PDF
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} style={{ color: 'primary' }}>
          <ListItemIcon>
            <DeleteIcon color="primary" />
          </ListItemIcon>
          DELETE
        </MenuItem>
      </Menu>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Patient Details
        </DialogTitle>
        <Container>
          <Stack spacing={3}>
            <PatientForm
              fetchPatientDetails={fetchPatientDetails}
              clinicId={patient?.refClinicId}
              token={token}
              clinicDisabled={true}
              handleCloseDialog={() => {
                setOpen(false);
                fetchPatientDetails(
                  moment(today).subtract(30, 'days').format('YYYY-MM-DD'),
                  moment(today).format('YYYY-MM-DD')
                );
              }}
              patient={patient}
            />
          </Stack>
        </Container>
      </Dialog>

      <Dialog open={pdfDialog} fullWidth maxWidth="md" onClose={handlePdfDialogClose}>
        <DialogContent sx={{ height: 'auto' }}>
          <PDFComponent
            patient={patient}
            handleInvoiceDialogClose={handlePdfDialogClose}
            whatsappSuccessMsg={whatsappSuccessMsg}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={deleteDialog} onClose={handleDeleteDialogClose}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? This action will delete the patient record
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button variant="outlined" color="error" onClick={handleDeletePatient} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomizedDialogs;
