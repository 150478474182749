import { SimpleCard } from 'app/components';
import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Icon, styled, Grid, Box, Card } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { getPatientsByClinicIdByDoctorIdFilterDate } from 'app/api-services/patient-ws';
import DpatientTable from '../dashboard/Tables/DpatientTable';
import PatientForm from './Forms/PatientForm';
import useAuth from 'app/hooks/useAuth';
import Norecord from 'app/components/norecord';
import SearchableDate from './patientSearchBar';
import moment from 'moment';
import dayjs from 'dayjs';
import Loader from 'app/components/Loader';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const DpatientList = () => {
  const today = new Date().toISOString().split('T')[0];
  const [patientsList, setPatientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(moment(today).subtract(30, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [openDialog, setOpenDialog] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [phoneFilter, setPhoneFilter] = useState(null);
  const { user } = useAuth();
  let doctorName = '';
  if (user.role == 'DOCTOR') {
    doctorName = `${user?.doctorName?.firstName} ${user?.doctorName?.lastName}`;
  }
  const filterPatientList = (phoneValue) => {
    const filtered = patientsList.filter(
      (item) => !phoneValue || item.patientPhone.toString().includes(phoneValue)
    );

    setFilteredData(filtered);
  };
  const handleCloseDialog = () => {
    fetchPatientDetails(fromDate, toDate);
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  useEffect(() => {
    fetchPatientDetails(fromDate, toDate);
  }, []);
  const fetchPatientDetails = (fromDate, toDate, phoneValue) => {
    getPatientsByClinicIdByDoctorIdFilterDate(
      user?.refClinicId,
      user?._id,
      fromDate,
      toDate,
      user?.authToken
    )
      .then((res) => {
        setPatientsList(res.data.data.reverse());
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
    if (phoneValue) {
      filterPatientList(phoneValue);
    }
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));

  return (
    <Container>
      <Loader loading={isLoading} />
      <StyledButton variant="contained" color="primary" onClick={handleOpenDialog}>
        <Icon className="icon">group_add</Icon>Add Patient
      </StyledButton>

      <Dialog onClose={handleCloseDialog} open={openDialog}>
        <DialogTitle>Add Patient</DialogTitle>
        <DialogContent>
          <PatientForm
            clinicId={user?.refClinicId}
            doctorDisabled={true}
            token={user?.authToken}
            doctorId={user?._id}
            doctorName={doctorName}
            handleCloseDialog={handleCloseDialog}
          />
        </DialogContent>
      </Dialog>
      {/* <Grid container sx={{ mb: '24px' }}> */}
      <Grid item xs={12} md={6}>
        <StyledCard elevation={6} sx={{ marginBottom: '20px' }}>
          <ContentBox>
            <Box ml="12px">
              {/* Use the PatientListDropdown component */}
              <SearchableDate
                setPhoneFilter={setPhoneFilter}
                fetchPatientDetails={fetchPatientDetails}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                patientList={patientsList}
              />
            </Box>
          </ContentBox>
        </StyledCard>
      </Grid>
      {/* </Grid> */}

      {patientsList?.length === 0 ? (
        <SimpleCard>
          <Norecord />
        </SimpleCard>
      ) : (
        <SimpleCard title=" Patient Details">
          <DpatientTable
            token={user?.authToken}
            fetchPatientDetails={fetchPatientDetails}
            patientsList={filteredData ? filteredData : patientsList}
          />
        </SimpleCard>
      )}
    </Container>
  );
};

export default DpatientList;
