import { SimpleCard } from 'app/components';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Icon,
  Card,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import CpatientTable from '../dashboard/Tables/CpatientTable';
import { getPatientsByClinicIdFilterDate } from 'app/api-services/patient-ws';
import { getDoctorDetails } from 'app/api-services/doctor-ws';
import PatientForm from './Forms/PatientForm';
import useAuth from 'app/hooks/useAuth';
import Norecord from 'app/components/norecord';
import DoctorList from './DoctorList';
import SearchableDate from './patientSearchBar';
import moment from 'moment';
import { checkAuthStatus } from 'app/api-services/whatsapp-ws';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import dayjs from 'dayjs';
import Loader from 'app/components/Loader';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const CpatientList = (clinicId) => {
  const today = new Date().toISOString().split('T')[0];
  const [patientList, setPatientList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [doctorNames, setDoctorNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fromDate, setFromDate] = useState(moment(today).subtract(30, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [filteredData, setFilteredData] = useState(null);
  const [phoneFilter, setPhoneFilter] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const { user } = useAuth();
  const [patientsList, setPatientsList] = useState([]);
  const [whatsappError, setWhatsappError] = useState(true);
  const navigate = useNavigate();

  let clinicName = '';
  if (user.role == 'CLINIC') {
    clinicName = `${user?.clinicName}`;
  }
  useEffect(() => {
    fetchPatientDetails(fromDate, toDate);
    fetchDoctorDetails();
    checkWhatsAppAuth();
  }, []);
  const checkWhatsAppAuth = async () => {
    try {
      const res = await checkAuthStatus();
      if (res.data === 'CONNECTED') {
        setWhatsappError(false);
      } else {
        setWhatsappError(true);
      }
    } catch (err) {
      console.error('Error checking auth status:', err);
    }
  };

  const fetchPatientDetails = (fromDate, toDate, phoneValue) => {
    getPatientsByClinicIdFilterDate(user?._id, fromDate, toDate, user?.authToken)
      .then((res) => {
        setPatientList(res.data.data.reverse());
        setIsLoading(false);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in listing Patient');
        setSnackBarType('error');
        showSnackBar();
      });
    if (phoneValue) {
      filterPatientList(phoneValue);
    }
  };
  const filterPatientList = (phoneValue) => {
    const filtered = patientList.filter(
      (item) => !phoneValue || item.patientPhone.toString().includes(phoneValue)
    );
    console.log(filtered, fromDate, toDate, phoneValue);
    setFilteredData(filtered);
  };

  const fetchDoctorDetails = (clinicId) => {
    getDoctorDetails(user?._id, user?.authToken)
      .then((res) => {
        const doctors = res.data.data.reverse();
        setDoctorList(doctors);
        const names = doctors.map((doctor) => ({ _id: doctor._id, doctorName: doctor.doctorName }));
        setDoctorNames(names);
        setIsLoading(false);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in fetching data');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const handleCloseDialog = () => {
    fetchPatientDetails(fromDate, toDate);
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    // fetchDoctorDetails(clinicId);
    setOpenDialog(true);
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));
  const patientopd = patientList.length > 0 ? patientList[0]?.patientId + 1 : undefined;
  return (
    <div>
      <Loader loading={isLoading} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        {whatsappError ? (
          <Alert severity="error" sx={{ marginBottom: '16px' }}>
            WhatsApp is not signed in. Please log in WhatsApp to continue.
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<WhatsAppIcon />}
              onClick={() => {
                navigate('/clinic/whatsapp');
              }}
              sx={{ ml: 2 }}
            >
              Login to WhatsApp
            </Button>
          </Alert>
        ) : null}

        <StyledButton variant="contained" color="primary" onClick={handleOpenDialog}>
          <Icon className="icon">group_add</Icon>Add Patient
        </StyledButton>
        <Dialog onClose={handleCloseDialog} open={openDialog}>
          <DialogTitle>Add Patient</DialogTitle>
          <DialogContent>
            <PatientForm
              patientopd={patientopd}
              token={user?.authToken}
              addFromClinic={true}
              clinicId={user?._id}
              handleCloseDialog={handleCloseDialog}
              doctorList={doctorNames}
            />
          </DialogContent>
        </Dialog>
        <Grid item xs={12} md={6}>
          <StyledCard elevation={6} sx={{ marginBottom: '20px' }}>
            <ContentBox>
              <Box ml="12px">
                <SearchableDate
                  setPhoneFilter={setPhoneFilter}
                  fetchPatientDetails={fetchPatientDetails}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  patientList={patientList}
                />
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>

        {patientList.length === 0 ? (
          <SimpleCard>
            <Norecord />
          </SimpleCard>
        ) : (
          <SimpleCard title="Patient Details">
            <CpatientTable
              token={user?.authToken}
              fetchPatientDetails={fetchPatientDetails}
              patientList={filteredData ? filteredData : patientList}
            />
          </SimpleCard>
        )}
      </Container>
    </div>
  );
};

export default CpatientList;
