import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';

const DoctorSearchable = ({
  setReferredDoctor,
  doctorOptions,
  onSelectDoctor,
  value,
  setRefDoctorId,
}) => {
  // const handleDoctorSelect = (event, selectedDoctor) => {
  //   onSelectDoctor(selectedDoctor);
  // };
  const handleDoctorSelect = (event, selectedDoctor, selectedDoctorId) => {
    console.log('Selected Doctor:', selectedDoctor);
    // onSelectDoctor(`${selectedDoctor.firstName} ${selectedDoctor.lastName}`);
    // onSelectDoctor(selectedDoctor ? `${selectedDoctor.firstName} ${selectedDoctor.lastName}` : '');
    onSelectDoctor(
      selectedDoctor ? `${selectedDoctor.firstName || ''} ${selectedDoctor.lastName || ''}` : ''
    );

    // Set the referred doctor using setReferredDoctor
    setRefDoctorId(selectedDoctorId);
    setReferredDoctor(selectedDoctor);
  };

  return (
    <Autocomplete
      options={doctorOptions}
      getOptionLabel={(doctor) => `${doctor.doctorName.firstName} ${doctor.doctorName.lastName}`}
      value={value}
      onChange={(event, val) => {
        if (val) {
          console.log(val);
          setReferredDoctor(`${val.doctorName.firstName}${' '}${val.doctorName.lastName}`);
          setRefDoctorId(val._id); // Assuming you want to set the doctorName
          // Set the _id
        } else {
          // Handle case when no option is selected
          setReferredDoctor('');
          setRefDoctorId('');
        }
      }}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      renderInput={(params) => (
        <TextValidator {...params} label="Search for a Doctor" variant="outlined" />
      )}
    />
  );
};

export default DoctorSearchable;
