import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const sendSMS = (smsRequest) => {
  const authKey = '52yY3vk4MeQSosMbz1ss';
  const urlPath = `https://restapi.smscountry.com/v0.1/Accounts/${authKey}/SMSes/`;
  const authToken = 'KVjfXFEvdVeTCHCvEh17wiMO6eukL4prECyyj7qH';

  const credentials = `${authKey}:${authToken}`;
  const base64Token = btoa(credentials);
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: smsRequest,
    // Authorization: `Basic ${token}`,
    additionalHeader: {
      Authorization: `Basic ${base64Token}`,
      'Content-Type': 'application/json',
    },
  });
};
