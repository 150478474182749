import { SimpleCard } from 'app/components';
import {
  Button,
  DialogTitle,
  DialogContent,
  Icon,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import AdminForm from './Forms/AdminForm';
import Dialog from '@mui/material/Dialog';
import AdminTable from './Tables/AdminTable';
import { getAllAdminDetails } from 'app/api-services/admin-ws';
import useAuth from 'app/hooks/useAuth';
import Loader from 'app/components/Loader';

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchAdminDetails();
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchAdminDetails();
  }, []);
  const fetchAdminDetails = () => {
    getAllAdminDetails(user?.authToken)
      .then((res) => {
        setAdminList(res.data.data.reverse());
        setIsLoading(false);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in adding Admin');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));

  return (
    <div>
      <Loader loading={isLoading} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon> Add Admin
        </StyledButton>

        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
          <DialogTitle>Admin Form</DialogTitle>
          <DialogContent>
            <AdminForm token={user?.authToken} handleCloseDialog={handleCloseDialog} />
          </DialogContent>
        </Dialog>

        <SimpleCard title="ADMIN DETAILS">
          <AdminTable
            token={user?.authToken}
            fetchAdminDetails={fetchAdminDetails}
            adminList={adminList}
          />
        </SimpleCard>
      </Container>
    </div>
  );
};

export default AdminList;
