import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Divider, Container, Card } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import SearchIcon from '@material-ui/icons/Search';

const DateFilter = ({ fetchReportsByDate, fromDate, setFromDate, toDate, setToDate }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  //   const [filteredPatients, setFilteredPatients] = useState([]);
  const inputRef = useRef(null);

  const handleFilterSubmit = () => {
    fetchReportsByDate(fromDate, toDate);
  };

  const handleItemClick = (selectedPatient) => {
    setInputValue(selectedPatient.patientPhone);
    closeDropdown();
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      closeDropdown();
    }
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              fullWidth
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To"
              fullWidth
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default DateFilter;
