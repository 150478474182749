import React from "react";
import {
  Card,
  CardContent,
  Typography,
  styled,
  Container,
  Grid,
  TextField,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

import logo from "./bgcontact.jpg";
import NavBar from "./Navbar";
import Footer from "./Footer";

const StyledCard = styled(Card)({
  width: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

// const Section = styled(Paper)({
//   height: "100%",
//   padding: "16px",
// });

const ContactUS = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
    <NavBar />
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        marginTop: isMobile ? "5vh" : "20vh",
      }}
    >
      <Grid style={{ marginTop: "5px" }}>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", fontSize: "48px", textAlign: "center" }}
        >
          Contact Us
        </Typography>

        <StyledCard>
          <CardContent>
            <Grid
              container
              spacing={2}
              style={{ width: "90%", margin: "auto", marginTop: "10px" }}
            >
              {/* Contact Form Section */}
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  padding: "15px",
                  // border: "1px solid grey",
                  borderRadius: "10px",
                  marginTop: "0px",
                }}
              >
                <Card
                  style={{
                    backgroundImage: `url(${logo})`,
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                    backgroundSize: "cover",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backgroundPosition: "center",
                    color: "white",
                  }}
                >
                  <Typography variant="h5" style={{ marginLeft: "30px" }}>
                    Reach us at
                  </Typography>
                  <CardContent>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: "20px", marginTop: "30px" }}
                        >
                          {/* <Typography variant="subtitle1">Full Name</Typography> */}
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            required
                            InputLabelProps={{
                              style: { color: "white" }, // Text color for the label
                            }}
                            InputProps={{
                              style: {
                                color: "white", // Text color for the input
                                borderColor: "white", // Border color
                                border: "1px solid",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          {/* <Typography variant="subtitle1">Email</Typography> */}
                          <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            required
                            InputLabelProps={{
                              style: { color: "white" }, // Text color for the label
                            }}
                            InputProps={{
                              style: {
                                color: "white", // Text color for the input
                                borderColor: "white", // Border color
                                border: "1px solid",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          {/* <Typography variant="subtitle1">Subject</Typography> */}
                          <TextField
                            label="Subject"
                            variant="outlined"
                            fullWidth
                            required
                            InputLabelProps={{
                              style: { color: "white" }, // Text color for the label
                            }}
                            InputProps={{
                              style: {
                                color: "white", // Text color for the input
                                borderColor: "white", // Border color
                                border: "1px solid",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          {/* <Typography variant="subtitle1">Message</Typography> */}
                          <TextField
                            label="Message"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            required
                            InputLabelProps={{
                              style: { color: "white" }, // Text color for the label
                            }}
                            InputProps={{
                              style: {
                                color: "white", // Text color for the input
                                borderColor: "white", // Border color
                                border: "1px solid",
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            style={{ backgroundColor: "	crimson" }}
                          >
                            Send Message
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>

              {/* Map Section */}
              <Grid
                item
                xs={12}
                md={6}
                //  style={{ border: "2px solid grey", borderRadius: "10px" }}
              >
                <Card style={{ height: "95%" }}>
                  <CardContent style={{ padding: 0, marginTop: "10px" }}>
                    {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
                  title="Maps Location"
                  width="100%"
                  height="600px"
                  // frameborder="20px"
                  style={{ border: 0 }}

                  allowfullscreen=""
                ></iframe> */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.8525095761306!2d75.91676197593189!3d14.435662081091905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba25801488f567%3A0x1dee8cf47f9a23f1!2sByteCrafts!5e0!3m2!1sen!2sin!4v1706867960894!5m2!1sen!2sin"
                      width="600"
                      height="550"
                      title="ByteCrafts"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              style={{ width: "100%", margin: "auto" }}
            >
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction="column" alignItems="center">
                      <a
                        href="https://www.google.com/maps?q=16th+cross,+Davangere"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar style={{ backgroundColor: "	#DC143C" }}>
                          <LocationOnIcon />
                        </Avatar>
                        <Typography variant="subtitle1" align="center">
                          {" "}
                          #1647/5, 16th cross, 5th Bus Stop
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          {" "}
                          Taralabalu Badavane, Davangere
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction="column" alignItems="center">
                      <a
                        href="tel:+916362718708"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar style={{ backgroundColor: "	#DC143C" }}>
                          <PhoneIcon />
                        </Avatar>
                        <Typography variant="subtitle1" align="center">
                          +91 6362718708
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction="column" alignItems="center">
                      <a
                        href="mailto:bytecrafts24@gmail.com"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar style={{ backgroundColor: "	#DC143C" }}>
                          <EmailIcon />
                        </Avatar>
                        <Typography variant="subtitle1" align="center">
                        info.refease@gmail.com
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <a
                        href="https://www.bytecrafts.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar style={{ backgroundColor: "	#DC143C" }}>
                          <LanguageIcon />
                        </Avatar>
                        <Typography variant="subtitle1" align="center">
                          Refease.in
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </Grid>
    </Container>
    <Footer />
    </div>
  );
};

export default ContactUS;
