import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Divider, Container, Card } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// import SearchIcon from '@material-ui/icons/Search';

const SearchableDate = ({
  fetchPatientDetails,
  patientList,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setPhoneFilter,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = patientList.filter((patient) =>
      patient.patientPhone.toString().includes(value)
    );
    setFilteredPatients(filtered);
  };

  const handleFilterSubmit = () => {
    fetchPatientDetails(fromDate, toDate, inputValue);
  };

  const handleItemClick = (selectedPatient) => {
    setInputValue(selectedPatient.patientPhone);
    closeDropdown();
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      closeDropdown();
    }
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleFromDateChange = (newValue) => {
    // Format the date to 'yyyy-mm-dd' before setting it in state
    setFromDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
  };

  const handleToDateChange = (newValue) => {
    // Format the date to 'yyyy-mm-dd' before setting it in state
    setToDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            type="text"
            value={inputValue}
            placeholder=" Phonenumber"
            onFocus={openDropdown}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              fullWidth
              value={fromDate ? dayjs(fromDate) : null}
              onChange={handleFromDateChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To"
              fullWidth
              value={toDate ? dayjs(toDate) : null}
              onChange={handleToDateChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>
      {isOpen && (
        <Card
          className="dropdown-menu"
          style={{
            width: '130px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: '1px solid ',
            borderColor: 'grey',
          }}
        >
          {filteredPatients.map((patient) => (
            <Grid
              style={{ border: '2px', margin: '2px', textAlign: 'center' }}
              fullWidth
              key={patient._id}
              onClick={() => handleItemClick(patient)}
              className={patient.patientPhone === inputValue ? 'active' : ''}
            >
              <Typography>{patient.patientPhone}</Typography>
              <Divider />
            </Grid>
          ))}
        </Card>
      )}
    </div>
  );
};

export default SearchableDate;
