import Typography from '@mui/material/Typography';
import { Avatar, Alert, Button, Grid, Snackbar, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addAdmin, updateAdminDetails } from 'app/api-services/admin-ws';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const AdminForm = ({ admin, token, handleCloseDialog }) => {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [adminName, setAdminName] = useState(admin?.adminName);
  const [email, setEmail] = useState(admin?.email);
  const [profileImage, setProfileImage] = useState(admin?.adminProfile);
  var adminRequest = {};
  adminRequest = {
    adminName: adminName,
    email: email,
    password: 'Admin@123',
    role: 'ADMIN',
  };
  var updateAdminRequest = {};
  updateAdminRequest = {
    _id: admin?._id,
    adminName: adminName,
    email: email,
    adminProfile: profileImage,
    role: 'ADMIN',
  };
  const handleSubmit = (event) => {
    addAdmin(adminRequest, token)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Admin Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Admin');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Admin');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };
  const updateAdminSubmit = (event) => {
    updateAdminDetails(updateAdminRequest, token)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setSnackBarMessage('Admin Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Admin');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarMessage('Error in adding Admin');
        setSnackBarType('error');
        showSnackBar();
      });
    console.log('submitted');
  };

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setProfileImage(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Clinic"
          src={`data:image/jpeg;base64,${profileImage}`}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Logo
          </Button>
        </label>
      </SimpleCard>
      {/* <Box py="12px" /> */}

      <ValidatorForm onSubmit={admin ? updateAdminSubmit : handleSubmit} onError={() => null}>
        <Typography variant="h6" gutterBottom>
          Personal Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="AdminName"
              id="standard-basic"
              value={adminName || ''}
              onChange={(val) => setAdminName(val.target.value)}
              errorMessages={['this field is required']}
              label="Adminname (Min length 4, Max length 20)"
              validators={['required', 'minStringLength: 4', 'maxStringLength: 20']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="email"
              label="Email"
              value={email || ''}
              onChange={(val) => setEmail(val.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {admin ? 'Save Changes' : 'Add Admin'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AdminForm;
