import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import React, { useEffect, useState } from 'react';
import AccountsForm from './AccountsForm';
import { deleteAccountDetails, getAccountsByClinicId } from 'app/api-services/accounts-ws';
import { useTheme } from '@emotion/react';
import moment from 'moment';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});
const Accounts = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [detailedAccount, setDetailedAccount] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();

  useEffect(() => {
    fetchAccountsByClinicId();
  }, []);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteAccount = () => {
    deleteAccountDetails(detailedAccount?._id, user?.authToken)
      .then((res) => {
        fetchAccountsByClinicId();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseDialog = () => {
    fetchAccountsByClinicId();
    setOpenDialog(false);
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));
  const fetchAccountsByClinicId = () => {
    getAccountsByClinicId(user?._id, user?.authToken)
      .then((res) => {
        setAccountDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {' '}
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon>Add Accounts
        </StyledButton>
        <Dialog onClose={handleCloseDialog} open={openDialog}>
          <DialogTitle>Accounts Details</DialogTitle>
          <DialogContent>
            <AccountsForm
              accounts={detailedAccount}
              clinicId={user?._id}
              fetchAccountsByClinicId={fetchAccountsByClinicId}
              token={user?.authToken}
              handleCloseDialog={handleCloseDialog}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={() => {
            setDeleteDialog(false);
          }}
          open={deleteDialog}
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to delete the account?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will delete the account details for ever. Are you sure you want to
              continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={() => {
                deleteAccount();
                setDeleteDialog(false);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {accountDetails ? (
        accountDetails.length == 0 ? (
          <Grid>No data</Grid>
        ) : (
          <Box width="100%" overflow="auto">
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>Name</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="center">Added Date</TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>Amount</TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>Account Details</TableCell>
                  <TableCell align={isXsScreen ? 'left' : 'center'}>Delete Account</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((account, index) => (
                    <TableRow
                      key={index}
                      style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                    >
                      <TableCell align={isXsScreen ? 'left' : 'center'}>{account?.name}</TableCell>
                      <TableCell align="left">{account?.category} </TableCell>
                      <TableCell align="center">
                        {moment(account?.date).format('YYYY-MM-DD')}
                      </TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>
                        {account?.amount}
                      </TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>
                        <Button
                          onClick={() => {
                            setDetailedAccount(account);
                            setOpenDialog(true);
                          }}
                        >
                          View Details
                        </Button>
                      </TableCell>
                      <TableCell align={isXsScreen ? 'left' : 'center'}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setDetailedAccount(account);
                            setDeleteDialog(true);
                          }}
                        >
                          Delete Account
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>

            <TablePagination
              sx={{ px: 2 }}
              page={page}
              component="div"
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={'Records per page'}
              count={accountDetails.length}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            />
          </Box>
        )
      ) : null}
    </>
  );
};

export default Accounts;
