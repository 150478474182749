import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Navbar from './Navbar';
import Footer from './Footer';
import Homepage from './Homepage';

const LandingScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Navbar />
      <Homepage />

      <Footer />
    </div>
  );
};

export default LandingScreen;
