import { authRoles } from './auth/authRoles';

export const navigations = [
  // { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard', auth: authRoles.admin },
  { name: 'Dashboard', path: '/dashboard/admin', icon: 'dashboard', auth: authRoles.admin },
  {
    name: 'Dashboard',
    path: '/dashboard/clinic',
    icon: 'dashboard',
    auth: authRoles.clinic,
  },
  {
    name: 'Dashboard',
    path: '/dashboard/doctor',
    icon: 'dashboard',
    auth: authRoles.doctor,
  },

  {
    name: 'Clinic List',
    path: '/admin/clinicList',
    icon: 'featured_play_list',
    auth: authRoles.admin,
  },
  {
    name: 'Patients',
    path: '/clinic/patientList',
    icon: 'list',
    auth: authRoles.clinic,
  },
  {
    name: 'Doctors',
    path: '/clinic/doctorList',
    icon: 'featured_play_list',
    auth: authRoles.clinic,
  },
  { name: 'Patients', path: '/doctor/patientList', icon: 'list', auth: authRoles.doctor },
  {
    name: 'About Clinic',
    path: '/doctor/aboutClinic',
    icon: 'info_outline',
    auth: authRoles.doctor,
  },
  {
    name: 'Admins',
    path: '/admin/adminList',
    icon: 'supervisor_account',
    auth: authRoles.admin,
  },
  {
    name: 'Doctor Report',
    path: '/clinic/doctorreport',
    icon: 'assessment',
    auth: authRoles.clinic,
  },
  {
    name: 'Services',
    path: '/clinic/services',
    icon: 'assessment',
    auth: authRoles.clinic,
  },
  {
    name: 'Accounts',
    path: '/clinic/accounts',
    icon: 'account_balance_wallet',
    auth: authRoles.clinic,
  },
  {
    name: 'Announcement',
    path: '/clinic/announcement',
    icon: 'assessment',
    auth: authRoles.clinic,
  },
  {
    name: 'Whatsapp',
    path: '/clinic/whatsapp',
    icon: 'settings_phone',
    auth: authRoles.clinic,
  },
];
