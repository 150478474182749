import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, useMediaQuery } from '@mui/material';
import img1 from './Nodata1.jpg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: '100vh',
  },
  image: {
    maxWidth: '900px',
    width: '100%', // Make the image responsive
    borderRadius: '150px',
    borderRadius: '5%',
    marginTop: '60px',
  },
  title: {
    color: '#330099',
    textAlign: 'center',
    margin: '20px', // Decreased margin for mobile view
    fontSize: '25px',
  },
  text: {
    fontSize: '15px',
    textAlign: 'center',
  },
}));

const Norecord = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container className={classes.container}>
      <img src={img1} alt="No Records Found" className={classes.image} />
      <Typography variant="h1" className={`${classes.title} ${isMobile ? 'mobile' : ''}`}>
        No Records Found!!
      </Typography>
      <Typography variant="body1" className={classes.text}>
        There are no records to display at the moment.
      </Typography>
    </Container>
  );
};

export default Norecord;
