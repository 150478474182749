// import {
//   Box,
//   Icon,
//   IconButton,
//   styled,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TablePagination,
//   TableRow,
// } from '@mui/material';
// import { useState } from 'react';
// import { saveAs } from 'file-saver';
// import Excel from 'exceljs';

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: 'pre',
//   '& thead': {
//     '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
//   },
//   '& tbody': {
//     '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
//   },
// }));

// const DoctorReportTable = ({ reportList }) => {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   console.log('report', reportList);
//   const handleChangePage = (_, newPage) => {
//     setPage(newPage);
//   };

//   function getDoctorReports(doctors) {
//     const uniqueNamesSet = new Set();
//     const doctorReports = {};

//     doctors.forEach((doctor) => {
//       const doctorName = doctor.refDoctorName;
//       const img2D = doctor ? doctor.image2D.length : 0;
//       const img3D = doctor ? doctor.image3D.length : 0;

//       uniqueNamesSet.add(doctorName);

//       // Add or update the img2D and img3D count for the doctor
//       if (!doctorReports[doctorName]) {
//         doctorReports[doctorName] = { img2D, img3D };
//       } else {
//         doctorReports[doctorName].img2D += img2D;
//         doctorReports[doctorName].img3D += img3D;
//       }
//     });

//     const uniqueDoctorNames = Array.from(uniqueNamesSet);
//     const result = uniqueDoctorNames.map((name) => ({
//       name,
//       img2D: doctorReports[name].img2D,
//       img3D: doctorReports[name].img3D,
//     }));

//     return result;
//   }

//   const doctorList = getDoctorReports(reportList);
//   console.log('new', doctorList);
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const exportToExcel = () => {
//     const workbook = new Excel.Workbook();
//     const worksheet = workbook.addWorksheet('Doctor Report');

//     // Add table headers
//     const headers = ['Sr. No.', 'Doctor Name', 'ImageCount2D', 'ImageCount3D'];
//     worksheet.addRow(headers);

//     // Add table data
//     doctorList.forEach((report, index) => {
//       worksheet.addRow([index + 1, report.name, report.img2D, report.img3D]);
//     });

//     // Generate Excel file
//     workbook.xlsx.writeBuffer().then((buffer) => {
//       saveAs(new Blob([buffer]), 'doctor_report.xlsx');
//     });
//   };

//   return (
//     <Box width="100%" overflow="auto">
// <Tooltip title="Export to Excel">
//         <IconButton onClick={exportToExcel}>
//           <GetAppIcon />
//         </IconButton>
//       </Tooltip>

//       <StyledTable>
//         <TableHead>
//           <TableRow>
//             <TableCell align="center">Sr. No.</TableCell>
//             <TableCell align="center">Doctor Name</TableCell>
//             <TableCell align="center">ImageCount2D</TableCell>
//             <TableCell align="center">ImageCount3D</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {doctorList
//             ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//             ?.map((report, index) => (
//               <TableRow key={index}>
//                 <TableCell align="center">{index + 1}</TableCell>
//                 <TableCell align="center">{report.name}</TableCell>
//                 <TableCell align="center">{report.img2D}</TableCell>
//                 <TableCell align="center">{report.img3D}</TableCell>
//               </TableRow>
//             ))}
//         </TableBody>
//       </StyledTable>

//       <TablePagination
//         sx={{ px: 2 }}
//         page={page}
//         component="div"
//         rowsPerPage={rowsPerPage}
//         labelRowsPerPage={'Records per page'}
//         count={reportList.length}
//         onPageChange={handleChangePage}
//         rowsPerPageOptions={[10, 25]}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         nextIconButtonProps={{ 'aria-label': 'Next Page' }}
//         backIconButtonProps={{ 'aria-label': 'Previous Page' }}
//       />
//     </Box>
//   );
// };

// export default DoctorReportTable;
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

const DoctorReportTable = ({ reportList }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log('report', reportList);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  function getDoctorReports(doctors) {
    const uniqueNamesSet = new Set();
    const doctorReports = {};

    doctors.forEach((doctor) => {
      const doctorName = doctor.refDoctorName;
      const img2D = doctor ? doctor.image2D.length : 0;
      const img3D = doctor ? doctor.image3D.length : 0;

      uniqueNamesSet.add(doctorName);

      // Add or update the img2D and img3D count for the doctor
      if (!doctorReports[doctorName]) {
        doctorReports[doctorName] = { img2D, img3D };
      } else {
        doctorReports[doctorName].img2D += img2D;
        doctorReports[doctorName].img3D += img3D;
      }
    });

    const uniqueDoctorNames = Array.from(uniqueNamesSet);
    const result = uniqueDoctorNames.map((name) => ({
      name,
      img2D: doctorReports[name].img2D,
      img3D: doctorReports[name].img3D,
    }));

    return result;
  }

  const doctorList = getDoctorReports(reportList);
  console.log('new', doctorList);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportToExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Doctor Report');

    // Add table headers with styling
    const headerRow = worksheet.addRow([
      'Sr. No.',
      'Doctor Name',
      'ImageCount2D',
      'ImageCount3D',
      'Total Image Count',
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC0C0C0' }, // Light Gray
      };
      cell.font = {
        bold: true,
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // Add table data with styling
    doctorList.forEach((report, index) => {
      const row = worksheet.addRow([
        index + 1,
        report.name,
        report.img2D,
        report.img3D,
        report.img2D + report.img3D * 2,
      ]);
      row.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // Increase row height
      row.height = 25; // Set the desired height here
      // Alternate row color
      row.fill =
        index % 2 === 0
          ? { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFCC' } }
          : undefined; // Set your desired colors here
    });

    // Auto size columns
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        maxLength = Math.max(maxLength, columnLength);
      });
      column.width = maxLength < 20 ? 20 : maxLength;
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), 'doctor_report.xlsx');
    });
  };

  return (
    <Box width="100%" overflow="auto">
      {/* <button onClick={exportToExcel}>Export to Excel</button> */}
      <Button
        variant="outlined"
        onClick={exportToExcel}
        style={{ color: 'green', borderColor: 'green' }}
      >
        Export to Excel
      </Button>

      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr. No.</TableCell>
            <TableCell align="center">Doctor Name</TableCell>
            <TableCell align="center">ImageCount2D</TableCell>
            <TableCell align="center">ImageCount3D</TableCell>
            <TableCell align="center">Total Image Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doctorList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((report, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{report.name}</TableCell>
                <TableCell align="center">{report.img2D}</TableCell>
                <TableCell align="center">{report.img3D}</TableCell>
                <TableCell align="center">{report.img2D + report.img3D * 2}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={reportList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default DoctorReportTable;
