import { ValidatorForm } from 'react-material-ui-form-validator';

export function Validator() {
  ValidatorForm.addValidationRule('isLastName', (value) => {
    // Ensure last name contains only alphabetic characters and spaces
    return /^[a-zA-Z\s]+$/.test(value);
  });

  //Valid age
  ValidatorForm.addValidationRule('isValidAge', (value) => {
    const age = parseInt(value, 10);
    return age >= 1;
  });

  //Phone number Valid
  ValidatorForm.addValidationRule('isTenDigits', (value) => {
    return /^\d{10}$/.test(value);
  });

  ValidatorForm.addValidationRule('isNotStartingWithZero', (value) => {
    return /^[1-9]/.test(value);
  });

  // Aadhaar validation rule
  ValidatorForm.addValidationRule('isAadhaar', (value) => {
    return /^\d{12}$/.test(value);
  });

  // PAN validation rule
  ValidatorForm.addValidationRule('isPAN', (value) => {
    return /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value);
  });

  // GST validation rule
  ValidatorForm.addValidationRule('isGST', (value) => {
    return /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/.test(value);
  });

  // TAN validator rule
  ValidatorForm.addValidationRule('isTAN', (value) => {
    return /^[A-Z]{3}[0-9]{5}[A-Z]{1}$/.test(value);
  });
  //Valid time
  ValidatorForm.addValidationRule('isValidTime', (value) => {
    return /^(?:[01]\d|2[0-3]):[0-5]\d|24:00$/.test(value);
  });

  return () => {
    ValidatorForm.removeValidationRule('isLastName');
    ValidatorForm.removeValidationRule('isValidAge');
    ValidatorForm.removeValidationRule('isTenDigits');
    ValidatorForm.removeValidationRule('isNotStartingWithZero');
    ValidatorForm.removeValidationRule('isAadhaar');
    ValidatorForm.removeValidationRule('isPAN');
    ValidatorForm.removeValidationRule('isGST');
    ValidatorForm.removeValidationRule('isTAN');
    ValidatorForm.removeValidationRule('isValidTime');
  };
}
