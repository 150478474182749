import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AdminDialog from '../models/AdminDialog';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});

const AdminTable = ({ token, fetchAdminDetails, adminList }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Name</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Email</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Role</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((admin, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
              >
                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {admin?.adminName?.replace(/^\w/, (c) => c.toUpperCase())}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>{admin?.email}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>{admin?.role}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <AdminDialog token={token} fetchAdminDetails={fetchAdminDetails} admin={admin} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={adminList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default AdminTable;

// import {
//   Box,
//   styled,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TablePagination,
//   TableRow,
// } from '@mui/material';
// import AdminDialog from '../models/AdminDialog';
// import { useState } from 'react';

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: 'pre',
//   '& thead': {
//     '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
//   },
//   '& tbody': {
//     '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
//   },
// }));

// const subscribarList = [
//   {
//     name: 'john doe',
//     date: '18 january, 2019',
//     amount: 1000,
//     status: 'close',
//     company: 'ABC Fintech LTD.',
//   },
//   {
//     name: 'kessy bryan',
//     date: '10 january, 2019',
//     amount: 9000,
//     status: 'open',
//     company: 'My Fintech LTD.',
//   },
//   {
//     name: 'kessy bryan',
//     date: '10 january, 2019',
//     amount: 9000,
//     status: 'open',
//     company: 'My Fintech LTD.',
//   },
//   {
//     name: 'james cassegne',
//     date: '8 january, 2019',
//     amount: 5000,
//     status: 'close',
//     company: 'Collboy Tech LTD.',
//   },
//   {
//     name: 'lucy brown',
//     date: '1 january, 2019',
//     amount: 89000,
//     status: 'open',
//     company: 'ABC Fintech LTD.',
//   },
//   {
//     name: 'lucy brown',
//     date: '1 january, 2019',
//     amount: 89000,
//     status: 'open',
//     company: 'ABC Fintech LTD.',
//   },
//   {
//     name: 'lucy brown',
//     date: '1 january, 2019',
//     amount: 89000,
//     status: 'open',
//     company: 'ABC Fintech LTD.',
//   },
//   {
//     name: 'lucy brown',
//     date: '1 january, 2019',
//     amount: 89000,
//     status: 'open',
//     company: 'ABC Fintech LTD.',
//   },
//   {
//     name: 'lucy brown',
//     date: '1 january, 2019',
//     amount: 89000,
//     status: 'open',
//     company: 'ABC Fintech LTD.',
//   },
// ];

// const AdminTable = ({ fetchAdminDetails, adminList }) => {
//   console.log('al', adminList);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const handleChangePage = (_, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
//     <Box width="100%" overflow="auto">
//       <StyledTable>
//         <TableHead>
//           <TableRow>
//             <TableCell align="center">Sr. No.</TableCell>
//             <TableCell align="left">Name</TableCell>
//             <TableCell align="center">Email</TableCell>
//             <TableCell align="center">Role</TableCell>
//             <TableCell align="center">Action</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {adminList
//             ?.reverse()
//             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//             .map((admin, index) => (
//               <TableRow
//                 key={index}
//                 style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
//               >
//                 {/* <TableRow key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}> */}
//                 <TableCell align="center">{index + 1}</TableCell>
//                 <TableCell align="left">{admin?.adminName}</TableCell>
//                 <TableCell align="center">{admin?.email}</TableCell>
//                 <TableCell align="center">{admin?.role}</TableCell>
//                 <TableCell align="center">
//                   <AdminDialog fetchAdminDetails={fetchAdminDetails} admin={admin} />
//                 </TableCell>
//               </TableRow>
//             ))}
//         </TableBody>
//       </StyledTable>

//       <TablePagination
//         sx={{ px: 2 }}
//         page={page}
//         component="div"
//         rowsPerPage={rowsPerPage}
//         labelRowsPerPage={'Records per page'}
//         count={subscribarList.length}
//         onPageChange={handleChangePage}
//         rowsPerPageOptions={[10, 25]}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         nextIconButtonProps={{ 'aria-label': 'Next Page' }}
//         backIconButtonProps={{ 'aria-label': 'Previous Page' }}
//       />
//     </Box>
//   );
// };

// export default AdminTable;
