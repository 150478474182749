import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getPatientDetails = (authToken) => {
  const urlPath = '/patient/getPatientDetails';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getPatientsByRefDoctorId = (doctorId, authToken) => {
  const urlPath = `/patient/getPatientsByRefDoctorId/${doctorId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getPatientsByClinicId = (clinicId, authToken) => {
  const urlPath = `/patient/getPatientsByRefClinicId/${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getPatientsByClinicIdFilterDate = (clinicId, fromDate, toDate, authToken) => {
  const urlPath = `/patient/getPatientsByRefClinicIdDate/${clinicId}/${fromDate}/${toDate}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const getPatientsByClinicIdByDoctorIdFilterDate = (
  clinicId,
  doctorId,
  fromDate,
  toDate,
  authToken
) => {
  const urlPath = `/patient/getPatientsByRefClinicIdRefDoctorIdDate/${clinicId}/${doctorId}/${fromDate}/${toDate}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
    // Authorization: `Bearer ${authToken}`,
  });
};

export const addPatient = (patientRequest, authToken) => {
  const urlPath = '/patient/addPatient';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: patientRequest,
    additionalHeader: additionalHeader,
  });
};

export const removePatient = (patientId, authToken) => {
  const urlPath = `/patient/removePatient/${patientId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const updatePatientDetails = (patientRequest, authToken) => {
  const urlPath = '/patient/updatePatient';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: patientRequest,
    additionalHeader: additionalHeader,
  });
};

export const updatePatientStatus = (patientRequest, authToken) => {
  const urlPath = '/patient/updateStatus';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: patientRequest,
    additionalHeader: additionalHeader,
  });
};
