import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Grid,
  Box,
  Icon,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Small } from 'app/components/Typography';
import { getPatientsByRefDoctorId } from 'app/api-services/patient-ws';
import useAuth from 'app/hooks/useAuth';
import DoughnutChart from './shared/Doughnut';
import { bgcolors } from 'app/utils/utils';
import PatientForm from './Forms/PatientForm';
import AnnouncementBanner from './shared/AnnouncementBanner';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

const DoctorDashboard = () => {
  const [patientsList, setPatientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  const fetchPatientDetails = () => {
    getPatientsByRefDoctorId(user?._id, user?.authToken)
      .then((res) => {
        setPatientsList(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching patient details:', err);
        setIsLoading(false);
      });
  };

  const countPatientsReferredToday = () => {
    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const endOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1,
      0,
      0,
      0
    );

    return patientsList.filter(
      (patient) =>
        new Date(patient.createdDate) >= startOfToday && new Date(patient.createdDate) < endOfToday
    ).length;
  };

  const countPatientsReferredInMonth = (month) => {
    return patientsList.filter((patient) => new Date(patient.referralDate).getMonth() === month)
      .length;
  };

  const countPatientsReferredLastMonth = () => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    console.log('lm', thirtyDaysAgo);
    return patientsList.filter(
      (patient) =>
        new Date(patient.createdDate) >= thirtyDaysAgo && new Date(patient.createdDate) <= today
    ).length;
  };

  const currentMonth = new Date().getMonth();

  const cardList = [
    {
      name: 'Number of Patients',
      amount: patientsList.length,
      icon: 'group',
      redirect: '/doctor/patientList',
    },
    {
      name: 'Total patients referred today',
      amount: countPatientsReferredToday(),
      icon: 'group',
      redirect: '/doctor/patientList',
    },
    {
      name: 'Number of Patients in last 30 days',
      amount: countPatientsReferredLastMonth(),
      icon: 'assessment',
    },
  ];

  const theme = useTheme();

  return (
    <div
      style={{ display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'center' }}
    >
      <AnnouncementBanner />

      <Grid container spacing={3} sx={{ mb: '24px' }}>
        {cardList.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <StyledCard elevation={6} component={Link} to={item?.redirect}>
              <ContentBox>
                <Icon className="icon">{item.icon}</Icon>
                <Box ml="12px">
                  <Small>{item.name}</Small>
                  <Heading>{item.amount}</Heading>
                </Box>
              </ContentBox>
              <Tooltip title="View Details" placement="top">
                <IconButton>
                  <Icon>arrow_right_alt</Icon>
                </IconButton>
              </Tooltip>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Box ml="12px">
                <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                  <Icon
                    className="icon"
                    style={{ height: '40px', color: 'white', marginRight: '8px' }}
                  >
                    group_add
                  </Icon>{' '}
                  Add Patient
                </Button>
                <Dialog onClose={handleCloseDialog} open={openDialog}>
                  <DialogTitle>Patient Form</DialogTitle>
                  <DialogContent>
                    <PatientForm
                      token={user?.authToken}
                      doctorId={user?._id}
                      clinicId={user?.refClinicId}
                      doctorName={`${user?.doctorName?.firstName} ${user?.doctorName?.lastName}`}
                      doctorDisabled={true}
                      handleCloseDialog={handleCloseDialog}
                    />
                  </DialogContent>
                </Dialog>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
        {/* Status Summary Card */}
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card sx={{ px: 2, py: 1, mb: 2 }}>
            <Title style={{ padding: '10px' }}>Status Summary</Title>
            <DoughnutChart
              data={[
                {
                  value: patientsList.filter((patient) => patient.status === 'REFERRED').length,
                  name: 'Referred Patients',
                },
                {
                  value: patientsList.filter((patient) => patient.status === 'REPORT_PENDING')
                    .length,
                  name: 'Report Pending',
                },
                {
                  value: patientsList.filter((patient) => patient.status === 'COMPLETED').length,
                  name: 'Completed',
                },
              ]}
              height="350px"
              color={[bgcolors.REFERRED, bgcolors.REPORT_PENDING, bgcolors.COMPLETED]}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DoctorDashboard;
