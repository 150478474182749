import React from "react";
import { Grid, Typography, Container, Box, Fab, Link } from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import logo from "./logo.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  // SVG width and Card width
  const svgWidth = 1200;

  return (
    <Grid
      style={{
        position: "relative",
        zIndex: 1,
        bottom: "0",
        height: "auto",
        marginBottom: "0",
        overflow: "hidden",
        // backgroundImage: 'linear-gradient(90deg, #c2e9fb 10%, #c2e9ff 39%)',
        // backgroundImage: "linear-gradient(90deg, #c2e9fb 24%, #cd5c5c 68%)",
        // backgroundImage: 'radial-gradient(circle, #b5cccc 53%, #51b7b7 99%)',
        backgroundImage: 'radial-gradient(circle,#207eba   33%,#e9f2f8 99%)',
        marginTop: "30px",
      }}
    >
      <svg
        preserveAspectRatio="none"
        viewBox={`0 0 ${svgWidth} 120`}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fill: "#ffffff",
          width: "172%",
          height: "65",
          transform: "scaleX(-1)",
          position: "absolute",
        }}
      >
        <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
      </svg>

      <Container
        maxWidth="lg"
        style={{
          marginTop: "9vh",
          paddingBottom: "2vh",
          alignContent: "center",
        }}
      >
        <Grid container spacing={5}>
          {/* Grid 1 */}
          <Grid item xs={12} sm={4} style={{ marginTop: "15px" }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              <img src={logo} alt="Elite Logo" style={{ height: "50px" }} />
            </Typography>
            {/* color="#e0dccc" */}
            <Typography variant="body2" color="black">
              ByteCrafts is a dynamic and innovative startup at the forefront of
              technological evolution, specializing in crafting cutting-edge
              solutions to meet the challenges of the digital age.
            </Typography>
          </Grid>
          {/* Grid 2 */}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              marginTop: "45px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="black" gutterBottom>
              Collabrate With Us
            </Typography>
            <Grid style={{ paddingLeft: "20px" }}>
            <NavLink to="/ContactUS">
            <Fab
                variant="extended"
               
                style={{ width: "200px", justifyItems: "center" }}
              >
                <NavigationIcon sx={{ mr: 2 }} />
                Connect Us
              </Fab>        </NavLink>
              
            </Grid>
          </Grid>
          {/* Grid 3 */}
          <Grid item xs={12} sm={4}>
            <Grid style={{ paddingLeft: "20px", marginTop: "40px" }}>
              <Typography
                variant="h6"
                color="black"
                gutterBottom
                marginBottom="10px"
              >
                Follow Us
              </Typography>
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Link
                      href="https://www.facebook.com/profile.php?id=61555965698271"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      marginBottom="10px"
                    >
                      <Facebook />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography marginLeft='5px'>ByteCrafts Dvg</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Link
                      href="https://www.instagram.com/byte_crafts?igsh=MXQzcWV2enQ2eHBxcg=="
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      marginBottom="10px"
                    >
                      <Instagram />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography marginLeft='5px'>byte_crafts</Typography>
                  </Grid>
                </Grid>
                <Link
                  href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <Twitter />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="black" align="center">
            {"Designed By "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bytecrafts.in/"
              color="inherit"
            >
              ByteCrafts
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

export default Footer;
