import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Grid,
  Input,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { bgcolors } from 'app/utils/utils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePatientReport } from 'app/api-services/patientReport-ws';
import moment from 'moment';

const RadioRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  '& .formControl': {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  '& .group': { margin: theme.spacing(1, 0) },
}));

export default function StatusDialog({
  postPatientStatus,
  patient,
  patientId,
  token,
  patientStatus,
  uploadPatientReport,
  imageCapturedDate,
  alreadyUploadedImages,
  fetchAlreadyUploadedImages,
  pdfFile,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(patientStatus);
  const [images, setImages] = useState([]);
  const [pdfReport, setPdfReport] = useState(pdfFile);
  const [patientReportRequest, setpatientReportRequest] = useState({});
  const [paymentMode, setPaymentMode] = useState('CASH');
  const [selectedDate, setSelectedDate] = useState(
    imageCapturedDate
      ? moment(imageCapturedDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  );
  let updatePatientRequest = {};
  updatePatientRequest = {
    _id: patientId,
    status: value,
    imageCapturedDate: selectedDate,
    paymentMode: paymentMode,
    pdf3Dreport: pdfReport,
  };
  const handleImageChange = (event) => {
    const files = event.target.files;

    console.log('Number of files:', files.length);

    if (files && files.length > 0) {
      const imageArray = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const currentFile = files[i];
        reader.onloadend = () => {
          imageArray.push({
            dataUrl: reader.result,
            filename: currentFile.name, // Store the original filename
          });

          // If all images are processed, update the state
          if (imageArray.length === files.length) {
            const parsedImages = imageArray.map(({ dataUrl, filename }) => {
              return parseDataUrl(dataUrl, filename);
            });

            // Assuming parseDataUrl returns an array of objects with filename, type, and data
            const patientReportRequests = parsedImages.map((parseData) => ({
              filename: `patientReport/${patient?.refClinicId}/${patient?.refDoctorId}/${patient?._id}/${parseData?.filename}`,
              contentType: parseData?.type,
              fileContent: parseData?.data,
            }));
            setpatientReportRequest(patientReportRequests);
            setImages(imageArray);
            if (imageArray.length !== 0) {
              setValue('COMPLETED');
            }
          }
        };
        reader.readAsDataURL(currentFile);
      }
    }
  };
  const onStatusUpdate = () => {
    uploadPatientReport(patientReportRequest, token);
    postPatientStatus(updatePatientRequest);
    handleClose();
  };
  const handleClickOpen = () => {
    const folderPath = `patientReport/${patient?.refClinicId}/${patient?.refDoctorId}/${patient?._id}/`;
    fetchAlreadyUploadedImages(folderPath);
    setOpen(true);
  };
  const handleClose = () => {
    setImages([]);
    setOpen(false);
  };
  const getColorForStatus = (status) => {
    return bgcolors[status] || '#000'; // Default to black if the status is not recognized
  };
  const Small = styled('small')(({ status }) => ({
    width: 50,
    height: 15,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: getColorForStatus(status),
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  }));

  function parseDataUrl(dataUrl, originalFilename) {
    const matches = dataUrl?.match(/^data:(.*?);base64,(.*)$/);

    if (!matches || matches.length !== 3) {
      throw new Error('Invalid Data URL format');
    }

    const [, type, data] = matches;
    const filename = originalFilename || `image.${type.split('/')[1]}`;

    return { filename, type, data };
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleDeleteImage = (index) => {
    // Create a copy of the array without the deleted image
    const deleteImage = [...alreadyUploadedImages];
    const reportFile = deleteImage[index].key;
    deletePatientReport(reportFile, token)
      .then((res) => {
        const folderPath = `patientReport/${patient?.refClinicId}/${patient?.refDoctorId}/${patient?._id}/`;
        fetchAlreadyUploadedImages(folderPath);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePdfChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Pdf = e.target.result;
        setPdfReport(base64Pdf);
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(pdfReport);

  return (
    <Box style={{ maxWidth: 'md' }}>
      <Small status={value} onClick={handleClickOpen}>
        {value}
      </Small>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ maxWidth: 'lg' }}
      >
        <DialogTitle id="alert-dialog-title">Update Patient Status</DialogTitle>

        <DialogContent>
          <RadioRoot>
            <FormControl component="fieldset" className="formControl">
              <RadioGroup
                value={value}
                name="gender1"
                className="group"
                aria-label="Gender"
                onChange={(val) => {
                  setValue(val.target.value);
                }}
              >
                {alreadyUploadedImages?.length > 0 && (
                  <div>
                    <h3>Old Patient Reports:</h3>
                    <Grid container spacing={2}>
                      {alreadyUploadedImages.map((image, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div style={{ position: 'relative' }}>
                            <a href={image.url} target="_blank" rel="noopener noreferrer">
                              <img
                                src={image.url}
                                alt={`${image.key?.split('/').pop()}`}
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '300px',
                                  marginBottom: '10px',
                                  borderRadius: '10px',
                                  border: '2px solid #333',
                                  padding: '5px',
                                  height: '200px',
                                }}
                              />
                            </a>

                            {hoveredIndex === index && (
                              <IconButton
                                onClick={() => handleDeleteImage(index)} // You need to implement handleDeleteImage function
                                style={{
                                  position: 'absolute',
                                  top: '5px',
                                  right: '5px',
                                  background: 'rgba(0, 0, 0, 0.15)',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}

                            {/* Delete
                            </button> */}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                <div>
                  <Grid>
                    <input
                      accept="image/*" // Specify the accepted file types (in this case, all image types)
                      style={{ display: 'none' }}
                      id="image-input"
                      type="file"
                      multiple // Allow multiple files
                      onChange={handleImageChange}
                    />
                    <label htmlFor="image-input">
                      <Button variant="contained" color="primary" component="span">
                        Upload Images
                      </Button>
                    </label>
                  </Grid>
                  <Grid>
                    <input
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      id="pdf-input"
                      type="file"
                      onChange={handlePdfChange}
                    />
                    <label htmlFor="pdf-input">
                      <Button variant="contained" color="primary" component="span">
                        PDF Report
                      </Button>
                    </label>
                  </Grid>
                </div>
                {images?.length > 0 && (
                  <div>
                    <h3>Preview:</h3>
                    <Grid container spacing={2}>
                      {images.map((image, index) => (
                        <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                          <img
                            src={image.dataUrl}
                            alt={`Uploaded ${index + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '300px',
                              marginBottom: '10px',
                              borderRadius: '10px',
                              border: '2px solid #333',
                              padding: '5px',
                              height: '200px',
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                {pdfReport ? (
                  <div>
                    <h3>PDF Preview:</h3>
                    <object data="" />
                    <iframe width="100%" height="600px" title="PDF Viewer" src={pdfReport}></iframe>
                  </div>
                ) : null}
                <FormControlLabel value="REFERRED" control={<Radio />} label="Referred" />
                <FormControlLabel
                  value="REPORT_PENDING"
                  control={<Radio />}
                  label="Report Pending"
                />
                <FormControlLabel value="COMPLETED" control={<Radio />} label="Completed" />
              </RadioGroup>
            </FormControl>
          </RadioRoot>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <FormControl
                style={{ border: '1px solid', padding: '8px', borderRadius: '8px' }}
                fullWidth
              >
                <Input
                  id="image-captured-date"
                  type="date"
                  value={selectedDate}
                  disableUnderline
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="paymentMode">Payment Mode</InputLabel>
                <Select
                  value={paymentMode}
                  onChange={(e) => {
                    setPaymentMode(e.target.value);
                  }}
                  label="Payment Mode"
                  input={<OutlinedInput label="Payment Mode" name="paymentMode" id="paymentMode" />}
                >
                  <MenuItem value="CASH">Cash</MenuItem>
                  <MenuItem value="UPI">UPI</MenuItem>
                  <MenuItem value="CARD">Card</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button onClick={onStatusUpdate} color="primary" autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
