import React, { Component } from 'react';

class TermsAndConditionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedTerms: false,
    };
  }

  handleAcceptanceChange = (e) => {
    this.setState({
      acceptedTerms: e.target.checked,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.acceptedTerms) {
      // User has accepted the terms, you can proceed with your logic here
      // You can also send the acceptance status to the server if needed.
      console.log('Terms and conditions accepted.');
    } else {
      // User has not accepted the terms, you can provide a message or take action accordingly
      alert('Please accept the Terms and Conditions before proceeding.');
    }
  };

  render() {
    return (
      <div>
        <h1>DoCM Terms and Conditions</h1>
        <form onSubmit={this.handleSubmit}>
          <div id="termsAndConditions">
            <p>
              <strong>Acceptance of Terms:</strong> By accessing or using [Your Company Name]
              (hereafter referred to as "the Platform"), you agree to abide by these Terms and
              Conditions and our Privacy Policy. If you do not agree to these terms, please refrain
              from using the Platform.
            </p>
            <p>
              <strong>Description of Service:</strong> The Platform is designed to facilitate the
              efficient referral of healthcare professionals and to streamline the coordination of
              patient care. It connects healthcare providers and offers tools for improved
              communication, scheduling, and information sharing.
            </p>
            <p>
              <strong>User Registration: </strong> To access specific features, users may need to
              register and provide accurate and current information during the registration process.
            </p>
            <p>
              <strong>User Responsibilities: </strong> This section outlines user responsibilities,
              which include using the platform for its intended purpose, complying with relevant
              laws and regulations, maintaining the confidentiality of their accounts, and ensuring
              the content they share complies with the law and respects the rights of others.
            </p>
            <p>
              <strong>Privacy Policy: </strong> Users' use of the platform is also governed by your
              privacy policy, which covers how you collect, use, and protect their personal
              information.
            </p>
            <p>
              <strong>Intellectual Property: </strong> This section establishes that all content on
              the platform, including text, graphics, logos, and software, is your company's
              property and protected by intellectual property laws.
            </p>
            <p>
              <strong>Limitation of Liability: </strong> Users are informed that your company does
              not guarantee the accuracy or completeness of the information on the platform, and
              they use it at their own risk. Your company is not liable for any damages or losses
              resulting from platform use.
            </p>
            <p>
              <strong>Termination: </strong> Your company retains the right to terminate or suspend
              user accounts and access to the platform if users violate the terms and conditions.
            </p>
            <p>
              <strong>Changes to Terms and Conditions: </strong>Users are informed that these terms
              and conditions may be updated periodically, and any changes will be effective upon
              posting on the platform. Users are responsible for reviewing the terms and conditions
              periodically.
            </p>
            <p>
              <strong>Governing Law: </strong> These terms and conditions are governed by the laws
              of your jurisdiction, specifying the legal framework that applies.
            </p>
            <p>
              <strong>Contact Information: </strong> Users are provided with a way to contact your
              company if they have questions or concerns related to the terms and conditions.
            </p>
          </div>

          <label>
            <input
              type="checkbox"
              name="acceptTerms"
              checked={this.state.acceptedTerms}
              onChange={this.handleAcceptanceChange}
            />
            I have read and agree to the Terms and Conditions
          </label>

          <button type="submit">Accept</button>
        </form>
      </div>
    );
  }
}

export default TermsAndConditionsForm;