// import React, { useState } from 'react';
// import { Grid } from '@mui/material';
// const Navbar =()=>{
//     return(
//     <div>
//     Navbar 
//     </div>
//     )
// }
// export default Navbar
// ScrollNavbar.js
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import DrawerComponent from "./DrawerComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import image3 from './refeaselogo.jpeg'

const buttonStyles = {
  color: "#FFFFFF",
  "&:hover": {
    color: "#e0dccc",
    backgroundColor: "transparent",
  },
};

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isScrolled, setIsScrolled] = useState(false);

  const [navbarWidth, setNavbarWidth] = useState("70%");
  const [navbarMargin, setNavbarMargin] = useState("15%");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      setIsScrolled(scrollTop > 0);

      setNavbarWidth(scrollTop > 0 ? "100%" : "70%");
      setNavbarMargin(scrollTop > 0 ? "0%" : "15%");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={isScrolled ? 4 : 0}
        style={{
          background: "#020f1c",
          width: isMobile ? "100%" : navbarWidth,
          transition: isMobile
            ? "none"
            : "width 0.5s ease-in-out, margin 0.5s ease-in-out, top 0.5s ease-in-out",
          marginLeft: isMobile ? "0%" : navbarMargin,
          marginRight: isMobile ? "0%" : navbarMargin,
          marginTop: isMobile ? "0" : isScrolled ? "0" : "90px",
        }}
      >
        <CssBaseline />
        <Toolbar sx={{ justifyContent: "space-between", paddingRight: "10px" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={image3} alt="College Logo" style={{ height: "60px" }} />
            <Typography
              variant="h6"
              style={{
                color: "#e0dccc",
                fontFamily: "protest-revolution-font",
                marginRight: "10px",
              }}
            >
              REF-EASE
            </Typography>
          </Typography>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <Toolbar style={{ justifyContent: "center" }}>
              <div>
                <Button
                  color="inherit"
                  sx={buttonStyles}
                  component={Link}
                  to="/"
                >
                  Home
                </Button>
                

                <Button
                  color="inherit"
                  sx={buttonStyles}
                  component={Link}
                  to="/ContactUS"
                >
                  Contact US
                </Button>
                <Button
                  color="inherit"
                  sx={buttonStyles}
                  component={Link}
                  to="/Log in"
                  
                >
                  Log in 
                </Button>
                
              </div>
            </Toolbar>
          )}
        </Toolbar>
      </AppBar>

      {/* Additional AppBar on top */}
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          // backgroundImage: "linear-gradient(90deg, #c2e9fb 24%, #cd5c5c 68%)",
          backgroundImage: 'radial-gradient(circle,#207eba   33%,#e9f2f8 99%)',
          width: "100%",
          top: 0,
          zIndex: 2,
          opacity: isScrolled ? 0 : 1,
          transition: "opacity 1.0s ease-in-out",
        }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between" }}
          style={{ justifyContent: "center" }}
        >
          <Typography
            variant="h6"
            style={{
              color: "#14153E",
              textAlign: "center",
              fontFamily: "cursive",
            }}
          >
            "REF-EASE : Referral made easier"
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
