import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Grid, Snackbar, styled } from '@mui/material';
import { Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addDoctor, updateDoctorDetails } from 'app/api-services/doctor-ws';
import { Validator } from 'app/utils/validator-utils';
import Loader from 'app/components/Loader';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));
Validator();

const DoctorForm = ({
  clinicId,
  disabled,
  doctor,
  token,
  profileImage,
  handleCloseDialog,
  setProfileImage,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(doctor?.doctorName?.firstName);
  const [lastName, setLastName] = useState(doctor?.doctorName?.lastName);
  const [email, setEmail] = useState(doctor?.email);
  const [phoneNumber, setPhoneNumber] = useState(doctor?.phoneNumber?.cell);
  const [buildingNo, setBuildingNo] = useState(doctor?.address?.buildingNo);
  const [street, setStreet] = useState(doctor?.address?.street);
  const [locality, setLocality] = useState(doctor?.address?.locality);
  const [district, setDistrict] = useState(doctor?.address?.district);
  const [state, setState] = useState(doctor?.address?.state || 'Karnataka');
  const [pincode, setPincode] = useState(doctor?.address?.pincode);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  var doctorRequest = {};
  doctorRequest = {
    doctorName: {
      firstName: firstName,
      lastName: lastName,
    },
    email: email,
    password: 'Doctor@123',
    phoneNumber: {
      cell: phoneNumber,
      whatsapp: phoneNumber,
    },
    refClinicId: clinicId,
    role: 'DOCTOR',
    profileImage: profileImage,
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };
  var updateDoctorRequest = {};
  updateDoctorRequest = {
    _id: doctor?._id,
    doctorName: {
      firstName: firstName,
      lastName: lastName,
    },
    email: email,
    phoneNumber: {
      cell: phoneNumber,
      whatsapp: phoneNumber,
    },
    profileImage: profileImage,
    address: {
      buildingNo: buildingNo,
      street: street,
      locality: locality,
      district: district,
      state: state,
      pincode: pincode,
    },
  };
  const addhandleSubmit = (event) => {
    setLoading(true);
    addDoctor(doctorRequest, token)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 'success') {
          setSnackBarMessage('Clinic Added Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Doctor');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        setLoading(false);
        setSnackBarMessage('Error in adding Doctor');
        setSnackBarType('error');
        showSnackBar();
      });
  };

  const updatehandleSubmit = (event) => {
    setLoading(true);
    updateDoctorDetails(updateDoctorRequest, token)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 'success') {
          setSnackBarMessage('Updated Doctor Details Successfully');
          setSnackBarType('success');
          showSnackBar();
          handleCloseDialog();
        } else {
          setSnackBarMessage('Error in adding Doctor');
          setSnackBarType('error');
          showSnackBar();
        }
      })
      .catch((err) => {
        setLoading(false);
        setSnackBarMessage('Error in adding Doctor');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        setProfileImage(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const validatePhoneNumber = (value) => {
    const cleanedValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters

    if (cleanedValue.length !== 10) {
      setPhoneNumberError('Phone number must be 10 digits');
      return false;
    }

    setPhoneNumberError('');
    return true;
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    validatePhoneNumber(value);
  };

  const handleFormSubmit = () => {
    if (validatePhoneNumber(phoneNumber)) {
      // Proceed with form submission or update data
      console.log('Valid phone number:', phoneNumber);
    } else {
      // Show an error message or prevent form submission
      console.log('Invalid phone number. Please correct the input.');
    }
  };

  return (
    <div>
      <Loader loading={loading} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Doctor"
          src={`data:image/jpeg;base64,${profileImage}`}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Image
          </Button>
        </label>
      </SimpleCard>
      <Box py="12px" />

      <ValidatorForm onSubmit={doctor ? updatehandleSubmit : addhandleSubmit} onError={() => null}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="FirsttName"
              id="standard-basic"
              value={firstName || ''}
              onChange={(val) => {
                setFirstName(val.target.value);
              }}
              errorMessages={['this field is required']}
              label="Firstname (Min length 4, Max length 20)"
              validators={['required', 'minStringLength: 4', 'maxStringLength: 20']}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Item> */}{' '}
            <TextField
              type="text"
              name="lastname"
              label="Lastname"
              onChange={(val) => {
                setLastName(val.target.value);
              }}
              value={lastName || ''}
              errorMessages={['Enter a valid Last Name']}
              validators={['isLastName']}
            />
            {/* </Item> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="email"
              disabled={disabled}
              label="Email"
              value={email || ''}
              onChange={(val) => {
                setEmail(val.target.value);
              }}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number" // Use "text" type for the phone number
              name="phoneNumber"
              label="Phone Number"
              value={phoneNumber || ''}
              onChange={(val) => {
                // Ensure that the entered value is numeric
                const numericValue = val.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                setPhoneNumber(numericValue);
              }}
              validators={['required', 'isNumber', 'isTenDigits', 'isNotStartingWithZero']} // Validate for 10 digits
              errorMessages={[
                'This field is required',
                'Must be a number',
                'Phone number must be 10 digits',
                'Phone number cannot start with 0',
              ]}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Address Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="buildingNo"
              onChange={(val) => {
                setBuildingNo(val.target.value);
              }}
              label="Clinic Name"
              value={buildingNo || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="street"
              onChange={(val) => {
                setStreet(val.target.value);
              }}
              label="Street"
              value={street || ''}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            <TextField
              type="text"
              name="locality"
              onChange={(val) => {
                setLocality(val.target.value);
              }}
              label="Locality"
              value={locality || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="district"
              onChange={(val) => {
                setDistrict(val.target.value);
              }}
              label="District"
              value={district || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="state"
              onChange={(val) => {
                setState(val.target.value);
              }}
              label="State"
              value={state || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="pincode"
              onChange={(val) => {
                setPincode(val.target.value);
              }}
              label="Pincode"
              value={pincode || ''}
              validators={['required', 'isNumber']}
              errorMessages={['This field is required', 'Must be a number']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {doctor ? 'Save Changes' : 'Add Doctor'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default DoctorForm;
