import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ClinicDialog from '../models/ClinicDialog';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});

const ClinicTable = ({ token, clinicList, fetchClinicDetails }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Name</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Phone No.</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Email</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clinicList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((clinic, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
              >
                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {clinic?.clinicName?.replace(/^\w/, (c) => c.toUpperCase())}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {clinic?.clinicPhone?.cell}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>{clinic?.email}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <ClinicDialog
                    token={token}
                    fetchClinicDetails={fetchClinicDetails}
                    clinic={clinic}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={clinicList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default ClinicTable;
