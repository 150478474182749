import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import ProfilePage from './views/profile/profilePage';
import { authRoles } from './auth/authRoles';
import LandingPage from './views/LandingScreen/LandingScreen';
import ContactUS from './views/LandingScreen/ContactUS';
import Navbar from './views/LandingScreen/Navbar';
import Footer from './components/Footer';
import JwtLogin from './views/sessions/JwtLogin';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <LandingPage /> },
  // { path: '/', element: <Navigate to="/session/signin" /> },
  { path: '/admin', element: <Navigate replace="dashboard/admin" /> },
  { path: '/clinic', element: <Navigate replace="dashboard/clinic" /> },
  { path: '/doctor', element: <Navigate replace="dashboard/doctor" /> },
  { path: '/user-profile', element: <ProfilePage /> },
  { path: '*', element: <NotFound /> },
  { path: '/ContactUS', element: <ContactUS /> },
  { path: '/Navbar', element: <Navbar /> },
  { path: '/Footer', element: <Footer /> },
  { path: '/Log in', element: <JwtLogin /> },
];

export default routes;
