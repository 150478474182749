import React, { useState } from 'react';
import { Card, Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tooth: {
    width: '30px',
    height: '30px',
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '2px',
    backgroundColor: '#fff',
    '&.selected': {
      backgroundColor: '#80cbc4',
    },
    '&.disabled': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
    [theme.breakpoints.down('sm')]: {
      // Styles for mobile view
      width: '20px',
      height: '20px',
      fontSize: '12px',
    },
  },
  teethContainer: {
    display: 'flex',
    overflowX: 'auto',
  },
  title: {
    fontSize: '20px', // default font size
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px', // font size for mobile view
    },
  },
  head: {
    marginLeft: '15px', // default font size
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px', // font size for mobile view
    },
  },
}));

const TeethSelector = ({
  lowerselectedTeeth,
  upperselectedTeeth,
  setLowerSelectedTeeth,
  setUpperSelectedTeeth,
  disable,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const upperTeeth = [
    { name: 8, value: 1 },
    { name: 7, value: 2 },
    { name: 6, value: 3 },
    { name: 5, value: 4 },
    { name: 4, value: 5 },
    { name: 3, value: 6 },
    { name: 2, value: 7 },
    { name: 1, value: 8 },
    { name: 1, value: 9 },
    { name: 2, value: 10 },
    { name: 3, value: 11 },
    { name: 4, value: 12 },
    { name: 5, value: 13 },
    { name: 6, value: 14 },
    { name: 7, value: 15 },
    { name: 8, value: 16 },
  ];
  const handleUpperToothClick = (tooth) => {
    setUpperSelectedTeeth((prevTeeth) => {
      const isAlreadySelected = prevTeeth.some((prevTooth) => prevTooth.value === tooth.value);

      if (isAlreadySelected) {
        // If the tooth is already selected, remove it
        return prevTeeth.filter((prevTooth) => prevTooth.value !== tooth.value);
      } else {
        // If the tooth is not selected, add it
        return [...prevTeeth, { value: tooth.value }];
      }
    });
  };
  const handleLowerToothClick = (tooth) => {
    setLowerSelectedTeeth((prevTeeth) => {
      const isAlreadySelected = prevTeeth.some((prevTooth) => prevTooth.value === tooth.value);

      if (isAlreadySelected) {
        // If the tooth is already selected, remove it
        return prevTeeth.filter((prevTooth) => prevTooth.value !== tooth.value);
      } else {
        // If the tooth is not selected, add it
        return [...prevTeeth, { value: tooth.value }];
      }
    });
  };

  const renderUpperTeeth = () => {
    return upperTeeth.map((tooth) => {
      const isSelected = upperselectedTeeth.some(
        (selectedTooth) => selectedTooth.value === tooth.value
      );
      return (
        <Paper
          key={tooth.value}
          className={`${classes.tooth} ${isSelected ? 'selected' : ''} ${
            disable ? 'disabled' : ''
          }`}
          onClick={() => !disable && handleUpperToothClick(tooth)}
        >
          {tooth.name}
        </Paper>
      );
    });
  };
  const renderLowerTeeth = () => {
    return upperTeeth.map((tooth) => {
      const isSelected = lowerselectedTeeth.some(
        (selectedTooth) => selectedTooth.value === tooth.value
      );
      return (
        <Paper
          key={tooth.value}
          // className={`${classes.tooth} ${isSelected ? 'selected' : ''}`}
          // onClick={() => handleLowerToothClick(tooth)}
          // disabled={disable}
          className={`${classes.tooth} ${isSelected ? 'selected' : ''} ${
            disable ? 'disabled' : ''
          }`}
          onClick={() => !disable && handleLowerToothClick(tooth)}
        >
          {tooth.name}
        </Paper>
      );
    });
  };

  return (
    <Grid container className={classes.head}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Upper Teeth Selector
      </Typography>
      <Grid className={classes.teethContainer} spacing={1}>
        {renderUpperTeeth()}
      </Grid>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Lower Teeth Selector
      </Typography>
      <Grid className={classes.teethContainer} spacing={1}>
        {renderLowerTeeth()}
      </Grid>
    </Grid>
  );
};
export default TeethSelector;
