import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ImageOptionForm from './ImageOptionForm';
function ActionButton({ is2D, clinicId, image, fetchImageOptions, fetchDeleteImage }) {
  const [selectedView, setSelectedView] = useState(null);
  const [viewDialog, setViewDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(item);
  };
  const handleView = (item) => {
    handleCloseMenu();
    setSelectedView(item);
    setViewDialog(true);
  };
  const handleEdit = () => {
    handleCloseMenu();
    setEditDialogOpen(true);
  };
  const handleDeleteSubmit = async () => {
    const removeImageRequest = {
      clinicId: clinicId,
      serviceName: image?.name,
      imageType: is2D ? '2D' : '3D',
      serviceCost: image?.serviceCost,
    };
    await fetchDeleteImage(removeImageRequest);
    handleDeleteDialogClose();
    handleCloseMenu();
    fetchImageOptions();
  };

  const handleDeleteDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDialogOpen(false);
  };
  const handleCloseEditDialog = () => {
    // fetchAccountsById();
    setEditDialogOpen(false);
  };

  return (
    <div>
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle>Edit Image Service</DialogTitle>
        <DialogContent>
          <ImageOptionForm
            disableName={true}
            is2D={is2D}
            handleCloseDialog={handleCloseEditDialog}
            image={image}
            fetchImageOptions={fetchImageOptions}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Delete Image Service</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the service
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>cancel</Button>
            <Button onClick={handleDeleteSubmit} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Button
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={(event) => handleClick(event, image)}
        startIcon={<MenuOpenIcon />}
      />
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
        }}
      >
        <MenuItem onClick={() => handleEdit(image)}>
          <ListItemIcon>
            <EditIcon style={{ color: '#66b5a3' }} />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDeleteDialogOpen(image)}>
          <ListItemIcon>
            <DeleteIcon style={{ color: 'red' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ActionButton;
