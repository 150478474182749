import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAccounts = (accountRequest, authToken) => {
  const urlPath = '/accounts/addAccounts';
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: accountRequest,
    additionalHeader: additionalHeader,
  });
};

export const updateAccounts = (accountRequest, authToken) => {
  const urlPath = `/accounts/accountsUpdate`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: accountRequest,
    additionalHeader: additionalHeader,
  });
};

export const getAccountsByClinicId = (clinicId, authToken) => {
  const urlPath = `/accounts/getAccountsByClinicId/${clinicId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};

export const deleteAccountDetails = (accountId, authToken) => {
  const urlPath = `/accounts/deleteAccounts/${accountId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader,
  });
};
