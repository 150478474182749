import { SimpleCard } from 'app/components';
import {
  Button,
  DialogTitle,
  DialogContent,
  Icon,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import ClinicForm from './Forms/ClinicForm';
import Dialog from '@mui/material/Dialog';

import ClinicTable from '../dashboard/Tables/ClinicTable';
import { getAllClinicDetails } from 'app/api-services/clinic-ws';
import useAuth from 'app/hooks/useAuth';
import Loader from 'app/components/Loader';

const ClinicList = () => {
  const [clinicList, setClinicList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();
  const [clinicLogo, setClinicLogo] = useState();
  const handleCloseDialog = () => {
    fetchClinicDetails();
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchClinicDetails();
  }, []);
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const fetchClinicDetails = () => {
    getAllClinicDetails(user?.authToken)
      .then((res) => {
        setClinicList(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in Loading Clinic Details');
        setSnackBarType('error');
        showSnackBar();
      });
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
  }));

  return (
    <div>
      <Loader loading={isLoading} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon>Add Clinic
        </StyledButton>

        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
          <DialogTitle>Clinic Form</DialogTitle>
          <DialogContent>
            <ClinicForm
              adminId={user?._id}
              token={user?.authToken}
              handleCloseDialog={handleCloseDialog}
              setOpenDialog={setOpenDialog}
              setClinicLogo={setClinicLogo}
              clinicLogo={clinicLogo}
            />
          </DialogContent>
        </Dialog>

        <SimpleCard title="CLINIC DETAILS">
          <ClinicTable
            token={user?.authToken}
            fetchClinicDetails={fetchClinicDetails}
            clinicList={clinicList}
          />
        </SimpleCard>
      </Container>
    </div>
  );
};

export default ClinicList;
