import React, { useState } from 'react';
import { Box, FormControl, RadioGroup, styled, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { bgcolors } from 'app/utils/utils';

const RadioRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  '& .formControl': {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  '& .group': { margin: theme.spacing(1, 0) },
}));

export default function PatientReportDisplay({
  patient,
  patientId,
  patientStatus,
  alreadyUploadedImages,
  fetchAlreadyUploadedImages,
}) {
  console.log(patient);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    const folderPath = `patientReport/${patient?.refClinicId}/${patient?.refDoctorId}/${patient?._id}/`;
    fetchAlreadyUploadedImages(folderPath);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getColorForStatus = (status) => {
    return bgcolors[status] || '#000'; // Default to black if the status is not recognized
  };
  const Small = styled('small')(({ status }) => ({
    width: 50,
    height: 15,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: getColorForStatus(status),
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  }));
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Box>
      <Small status={patientStatus} onClick={handleClickOpen}>
        {patientStatus === 'COMPLETED' ? `View Report` : patientStatus}
      </Small>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Patient Reports</DialogTitle>

        <DialogContent>
          <RadioRoot>
            <FormControl component="fieldset" className="formControl">
              <div>
                {alreadyUploadedImages.length > 0 && (
                  <div>
                    <Grid container spacing={2}>
                      {alreadyUploadedImages.map((image, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div style={{ position: 'relative' }}>
                            <a href={image.url} target="_blank" rel="noopener noreferrer">
                              <img
                                src={image.url}
                                alt={`Already Uploaded ${index + 1}`}
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '300px',
                                  marginBottom: '10px',
                                  borderRadius: '10px',
                                  border: '2px solid #333',
                                  padding: '5px',
                                  height: '200px',
                                }}
                              />
                            </a>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                {patient?.pdf3Dreport && (
                  <Grid>
                    <h3>Pdf Report</h3>
                    <iframe
                      width="100%"
                      height="600px"
                      title="PDF Viewer"
                      src={patient?.pdf3Dreport}
                    ></iframe>
                  </Grid>
                )}
              </div>
            </FormControl>
          </RadioRoot>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
