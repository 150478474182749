import React, { useEffect } from 'react';

const Tawk = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/6673995019f6c616eadc1cf9/1i0potpjb'; // Use your actual script URL
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    script.onload = () => {
      console.log('Tawk.to script loaded successfully');
    };

    script.onerror = (error) => {
      console.error('Tawk.to script failed to load:', error);
    };

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      if (firstScript.parentNode) {
        firstScript.parentNode.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default Tawk;

// import React from 'react';

// function Tawk() {
//   return (
//     <div>
//       <script src="https://embed.tawk.to/6673995019f6c616eadc1cf9/1i0potpj" async />
//     </div>
//   );
// }

// export default Tawk;

// const Tawk = () => {
//   React.useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://embed.tawk.to/6673995019f6c616eadc1cf9/1i0potpjb';
//     script.async = true;
//     document.body.appendChild(script);
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return null;
// };

// export default Tawk;
