// import React, { useRef, useState, useEffect } from 'react';
// import {
//   Button,
//   Card,
//   CardContent,
//   Divider,
//   Grid,
//   Paper,
//   Table,
//   TableContainer,
//   Typography,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import generatePDF, { Margin } from 'react-to-pdf';
// import DownloadIcon from '@mui/icons-material/Download';
// import CloseIcon from '@mui/icons-material/Close';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import logoImage from './logoddi.jpeg';
// import moment from 'moment';
// import { sendPdf, checkAuthStatus } from 'app/api-services/whatsapp-ws'; // Import sendPdf function

// const options = {
//   filename: 'page.pdf',
//   page: {
//     margin: Margin.MEDIUM,
//   },
// };

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
//   headerCell: {
//     borderBottom: '2px solid black',
//   },
// });

// const calculateTotal = (data) => {
//   return data?.reduce((total, item) => total + item.cost, 0);
// };

// const PDFComponent = ({ handleInvoiceDialogClose, patient }) => {
//   const targetRef = useRef();
//   const classes = useStyles();
//   const [isWhatsAppConnected, setIsWhatsAppConnected] = useState(false);

//   useEffect(() => {
//     const checkWhatsAppConnection = async () => {
//       try {
//         const res = await checkAuthStatus();
//         setIsWhatsAppConnected(res.data === 'CONNECTED');
//       } catch (error) {
//         console.error('Failed to check WhatsApp status:', error);
//         setIsWhatsAppConnected(false);
//       }
//     };

//     checkWhatsAppConnection();
//   }, []);

//   // Calculate total cost for 2D and 3D images
//   const totalServiceCost3D = patient.image3D.reduce(
//     (total, image) => total + parseInt(image.serviceCost),
//     0
//   );
//   const totalServiceCost2D = patient.image2D.reduce(
//     (total, image) => total + parseInt(image.serviceCost),
//     0
//   );
//   const options1 = {
//     method: 'build',
//     filename: `${patient.patientName.firstName}_recipt.pdf`,
//     page: {
//       margin: Margin.MEDIUM,
//       format: 'A4',
//     },
//   };
//   const totalServiceCost = isNaN(totalServiceCost3D)
//     ? totalServiceCost2D
//     : isNaN(totalServiceCost2D)
//     ? totalServiceCost3D
//     : totalServiceCost3D + totalServiceCost2D;

//   // Function to send PDF via WhatsApp
//   const sendPDFViaWhatsApp = async () => {
//     try {
//       // Generate PDF using the existing generatePDF function
//       const pdf = await generatePDF(targetRef, options1);

//       // Output the PDF to a Blob
//       const pdfBlob = pdf.output('blob');

//       // Convert the Blob to a base64 string
//       const reader = new FileReader();
//       reader.onload = async () => {
//         const base64Data = reader.result.split(',')[1]; // Extract base64 data part
//         await sendPdf({
//           phone: '916362718708',
//           pdf: base64Data,
//           filename: options1.filename,
//         });
//       };
//       reader.readAsDataURL(pdfBlob);
//     } catch (error) {
//       console.error('Error generating or processing PDF:', error);
//     }
//   };

//   return (
//     <div>
//       <div ref={targetRef}>
//         <Card style={{ margin: '10px', border: '2px solid black' }}>
//           <CardContent>
//             <Grid container spacing={2} justifyContent="space-between">
//               <Grid item xs={12} md={2}>
//                 <img src={logoImage} alt="Logo" style={{ width: '100px' }} />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Typography style={{ fontWeight: 'bold' }} variant="h5">
//                   Dental Digital Imaging
//                 </Typography>
//                 <Typography>1st Floor, Ganesha Complex, Opp. to Dental College,</Typography>
//                 <Typography>Dental College Road, M.C.C. 'B' Block, Davangere-577004</Typography>
//               </Grid>
//               <Grid item xs={12} md={4} sx={{ textAlign: 'right', marginTop: '20px' }}>
//                 <b>CASH RECEIPT</b>
//               </Grid>
//             </Grid>
//             <Grid container spacing={2} justifyContent="space-between">
//               <Grid item xs={12} md={6}>
//                 <Typography>
//                   <b>Invoice Number</b>
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
//                 <Typography>
//                   <b>Date</b>: {moment(patient?.createdDate).format('YYYY-MM-DD')}
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Divider
//               sx={{
//                 border: '1px solid black',
//                 width: '100%',
//                 marginTop: '10px',
//                 marginBottom: '10px',
//               }}
//             />
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 <Typography>
//                   <b>Received sum of ₹ {totalServiceCost}</b>
//                 </Typography>
//                 <br />
//                 <Typography>
//                   <b>Towards the (Name)</b>
//                 </Typography>
//                 <div className={classes.imageContainer}>
//                   <Typography>
//                     <b>Radiography 3D images: </b>
//                   </Typography>
//                   <ul>
//                     {patient.image3D.map((image, index) => (
//                       <li key={index}>
//                         {image?.sms}, &nbsp; Cost: ₹{image.serviceCost}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </Grid>
//               <Grid item xs={12} md={4} sx={{ marginTop: '65px' }}>
//                 <div className={classes.imageContainer}>
//                   <Typography>
//                     <b>Radiography 2D images: </b>
//                   </Typography>
//                   <ul>
//                     {patient.image2D.map((image, index) => (
//                       <li key={index}>
//                         {image?.sms}, &nbsp; Cost: ₹{image.serviceCost}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </Grid>
//             </Grid>

//             <Typography>
//               <b>Radiography from </b>
//               {patient.patientName.firstName} {patient.patientName.lastName}
//             </Typography>
//             <br />
//             <Typography>
//               <b>Referred by Dr</b> {patient.refDoctorName}
//             </Typography>

//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <TableContainer component={Paper}>
//                   <Table className={classes.table} aria-label="invoice table">
//                     {/* Place your table content here */}
//                   </Table>
//                 </TableContainer>
//                 <Divider
//                   sx={{
//                     border: '1px solid black',
//                     width: '100%',
//                     marginTop: '10px',
//                     marginBottom: '10px',
//                   }}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container justifyContent="flex-end" paddingRight="4vh">
//               <Grid item xs={12} md={6}>
//                 <div
//                   style={{
//                     backgroundColor: '#f0f0f0',
//                     borderRadius: '5px',
//                     padding: '5px',
//                     display: 'inline-block',
//                     marginTop: '20px',
//                   }}
//                 >
//                   <Typography variant="h6">₹ {totalServiceCost}</Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
//                 <Grid sx={{ textAlign: 'center' }}>
//                   <Typography style={{}}>
//                     For <b>DENTAL DIGITAL IMAGING</b>
//                   </Typography>
//                   <Typography sx={{ marginBottom: '30px' }}></Typography>
//                   <Typography>Signature</Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </CardContent>
//         </Card>
//       </div>
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           marginTop: '10px',
//           marginLeft: '20px',
//           marginRight: '20px',
//         }}
//       >
//         <Button
//           variant="contained"
//           color="success"
//           style={{ color: 'white', fontSize: '0.75rem', padding: '6px 12px', width: '150px' }}
//           // onClick={() => {
//           //   sendPDFViaWhatsApp();
//           //   handleInvoiceDialogClose();
//           // }}
//           onClick={sendPDFViaWhatsApp}
//           startIcon={<WhatsAppIcon />}
//           disabled={!isWhatsAppConnected}
//         >
//           WhatsApp
//         </Button>

//         <div style={{ display: 'flex' }}>
//           <Button
//             variant="contained"
//             color="primary"
//             style={{
//               backgroundColor: 'primary',
//               color: 'white',
//               fontSize: '0.75rem',
//               padding: '6px 12px',
//               width: '90px',
//             }}
//             onClick={() => {
//               generatePDF(targetRef, options, { filename: 'page.pdf' });
//               handleInvoiceDialogClose();
//             }}
//             startIcon={<DownloadIcon />}
//           >
//             PDF
//           </Button>
//           {/* <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               backgroundColor: '#f0f0f0',
//               color: 'black',
//               fontSize: '0.75rem',
//               padding: '6px 12px',
//               width: '90px',
//               marginLeft: '10px',
//             }}
//             onClick={sendPDFViaWhatsApp}
//             startIcon={<WhatsAppIcon />}
//             disabled={!isWhatsAppConnected}
//           >
//             WhatsApp
//           </Button> */}
//           <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               backgroundColor: '#f0f0f0',
//               color: 'black',
//               fontSize: '0.75rem',
//               padding: '6px 12px',
//               width: '90px',
//               marginLeft: '10px', // Add margin between the buttons
//             }}
//             onClick={handleInvoiceDialogClose}
//             startIcon={<CloseIcon />}
//           >
//             Cancel
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PDFComponent;

import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableContainer,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import generatePDF, { Margin } from 'react-to-pdf';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logoImage from './logoddi.jpeg';
import moment from 'moment';
import { sendPdf, checkAuthStatus } from 'app/api-services/whatsapp-ws'; // Import sendPdf function

const options = {
  filename: 'page.pdf',
  page: {
    margin: Margin.MEDIUM,
  },
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    borderBottom: '2px solid black',
  },
});

const calculateTotal = (data) => {
  return data?.reduce((total, item) => total + item.cost, 0);
};

const PDFComponent = ({ handleInvoiceDialogClose, patient, whatsappSuccessMsg }) => {
  const targetRef = useRef();
  const classes = useStyles();
  const [isWhatsAppConnected, setIsWhatsAppConnected] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  useEffect(() => {
    const checkWhatsAppConnection = async () => {
      try {
        const res = await checkAuthStatus();
        setIsWhatsAppConnected(res.data === 'CONNECTED');
      } catch (error) {
        console.error('Failed to check WhatsApp status:', error);
        setIsWhatsAppConnected(false);
      }
    };

    checkWhatsAppConnection();
  }, []);

  const totalServiceCost3D = patient.image3D.reduce(
    (total, image) => total + parseInt(image.serviceCost),
    0
  );
  const totalServiceCost2D = patient.image2D.reduce(
    (total, image) => total + parseInt(image.serviceCost),
    0
  );
  const options1 = {
    method: 'build',
    filename: `${patient.patientName.firstName}_recipt.pdf`,
    page: {
      margin: Margin.MEDIUM,
      format: 'A4',
    },
  };
  const totalServiceCost = isNaN(totalServiceCost3D)
    ? totalServiceCost2D
    : isNaN(totalServiceCost2D)
    ? totalServiceCost3D
    : totalServiceCost3D + totalServiceCost2D;

  const sendPDFViaWhatsApp = async () => {
    try {
      const pdf = await generatePDF(targetRef, options1);
      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      reader.onload = async () => {
        const base64Data = reader.result.split(',')[1];
        await sendPdf({
          phone: `91${patient?.patientPhone}`,
          pdf: base64Data,
          filename: options1.filename,
        });
        handleInvoiceDialogClose();
        whatsappSuccessMsg();
      };
      reader.readAsDataURL(pdfBlob);
    } catch (error) {
      console.error('Error generating or processing PDF:', error);
      // Show error message on failure
      setSnackbarMessage('Failed to send PDF.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div ref={targetRef}>
        <Card style={{ margin: '10px', border: '2px solid black' }}>
          <CardContent>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={2}>
                <img src={logoImage} alt="Logo" style={{ width: '100px' }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography style={{ fontWeight: 'bold' }} variant="h5">
                  Dental Digital Imaging
                </Typography>
                <Typography>1st Floor, Ganesha Complex, Opp. to Dental College,</Typography>
                <Typography>Dental College Road, M.C.C. 'B' Block, Davangere-577004</Typography>
              </Grid>
              <Grid item xs={12} md={4} sx={{ textAlign: 'right', marginTop: '20px' }}>
                <b>CASH RECEIPT</b>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <Typography>
                  <b>Invoice Number</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                <Typography>
                  <b>Date</b>: {moment(patient?.createdDate).format('YYYY-MM-DD')}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                border: '1px solid black',
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography>
                  <b>Received sum of ₹ {totalServiceCost}</b>
                </Typography>
                <br />
                <Typography>
                  <b>Towards the (Name)</b>
                </Typography>
                <div className={classes.imageContainer}>
                  <Typography>
                    <b>Radiography 3D images: </b>
                  </Typography>
                  <ul>
                    {patient.image3D.map((image, index) => (
                      <li key={index}>
                        {image?.sms}, &nbsp; Cost: ₹{image.serviceCost}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} md={4} sx={{ marginTop: '65px' }}>
                <div className={classes.imageContainer}>
                  <Typography>
                    <b>Radiography 2D images: </b>
                  </Typography>
                  <ul>
                    {patient.image2D.map((image, index) => (
                      <li key={index}>
                        {image?.sms}, &nbsp; Cost: ₹{image.serviceCost}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
            </Grid>

            <Typography>
              <b>Radiography from </b>
              {patient.patientName.firstName} {patient.patientName.lastName}
            </Typography>
            <br />
            <Typography>
              <b>Referred by Dr</b> {patient.refDoctorName}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="invoice table">
                    {/* Place your table content here */}
                  </Table>
                </TableContainer>
                <Divider
                  sx={{
                    border: '1px solid black',
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" paddingRight="4vh">
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderRadius: '5px',
                    padding: '5px',
                    display: 'inline-block',
                    marginTop: '20px',
                  }}
                >
                  <Typography variant="h6">₹ {totalServiceCost}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                <Grid sx={{ textAlign: 'center' }}>
                  <Typography style={{}}>
                    For <b>DENTAL DIGITAL IMAGING</b>
                  </Typography>
                  <Typography sx={{ marginBottom: '30px' }}></Typography>
                  <Typography>Signature</Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <Button
          variant="contained"
          color="success"
          style={{ color: 'white', fontSize: '0.75rem', padding: '6px 12px', width: '150px' }}
          onClick={sendPDFViaWhatsApp}
          startIcon={<WhatsAppIcon />}
          disabled={!isWhatsAppConnected}
        >
          WhatsApp
        </Button>

        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: 'primary',
              color: 'white',
              fontSize: '0.75rem',
              padding: '6px 12px',
              width: '90px',
            }}
            onClick={() => {
              generatePDF(targetRef, options, { filename: 'page.pdf' });
              handleInvoiceDialogClose();
            }}
            startIcon={<DownloadIcon />}
          >
            PDF
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: '#f0f0f0',
              color: 'black',
              fontSize: '0.75rem',
              padding: '6px 12px',
              width: '90px',
              marginLeft: '10px',
            }}
            onClick={handleInvoiceDialogClose}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </div>
      </div>

      {/* Snackbar for success/failure messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PDFComponent;
