import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AnnouncementForm from './AnnouncementForm';
import { duration } from 'moment';

function AnnouncementActionButton({
  announcement,
  user,
  fetchAnnouncements,
  handleDeleteAnnouncement,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditDialogOpen = () => {
    handleCloseMenu();
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    handleCloseMenu();
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteSubmit = async () => {
    await handleDeleteAnnouncement(announcement._id);
    handleDeleteDialogClose();
    handleCloseMenu();
    fetchAnnouncements();
  };

  return (
    <div>
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Announcement</DialogTitle>
        <DialogContent>
          <AnnouncementForm
            announcement={announcement}
            handleCloseDialog={handleEditDialogClose}
            fetchAnnouncements={fetchAnnouncements}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Delete Announcement</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this announcement?</DialogContentText>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Cancel</Button>
            <Button onClick={handleDeleteSubmit} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Button
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<MenuOpenIcon style={{ color: '#66b5a3' }} />}
      />
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
        }}
      >
        <MenuItem onClick={() => handleEditDialogOpen(announcement)}>
          <ListItemIcon>
            <EditIcon style={{ color: '#66b5a3' }} />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteDialogOpen}>
          <ListItemIcon>
            <DeleteIcon style={{ color: '#66b5a3' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AnnouncementActionButton;
