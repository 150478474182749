import React, { useEffect, useState } from 'react';
import { getAnnouncementByClinicId } from '../../../api-services/announcement-ws';
import useAuth from 'app/hooks/useAuth';
import { styled, Box, Typography } from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PolicyIcon from '@mui/icons-material/Policy';
import PublicIcon from '@mui/icons-material/Public';
import announcementImage from '../announcement/announcement_banner.jpg';

const AnnouncementBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${announcementImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  height: '250px',
  width: '100%',
  marginBottom: '10px',
  borderRadius: '10px',
}));

const AnnouncementContent = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  maxWidth: '600px',
}));

const DurationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '50%',
  width: '70px',
  height: '70px',
  marginLeft: 'auto',
  padding: theme.spacing(1),
}));

const AnnouncementBanner = ({ clinicId }) => {
  const [announcement, setAnnouncement] = useState(null);
  const [remainingTime, setRemainingTime] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await getAnnouncementByClinicId(user?.refClinicId, user?.authToken);
        const latestAnnouncement = response.data.data.reverse()[0];
        setAnnouncement(latestAnnouncement);
        if (latestAnnouncement) {
          startCountdown(latestAnnouncement.duration);
        }
      } catch (error) {
        console.error('There was an error fetching the announcement!', error);
      }
    };
    fetchAnnouncement();
  }, [user?.refClinicId, user?.authToken]);

  const startCountdown = (duration) => {
    const endTime = new Date(duration).getTime();
    setInterval(() => {
      const now = new Date().getTime();
      const remaining = endTime - now;
      if (remaining <= 0) {
        setRemainingTime('00:00:00');
        return;
      }
      const hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
      setRemainingTime(
        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }, 1000);
  };

  const getTypeBackgroundColor = (type) => {
    switch (type) {
      case 'Urgent':
        return 'red';
      case 'Warning':
        return 'orange';
      case 'Information':
        return 'blue';
      case 'Reminder':
        return 'green';
      case 'Promotion':
        return 'purple';
      case 'Event':
        return 'teal';
      case 'Policy Update':
        return 'brown';
      case 'Holiday Notice':
        return 'yellow';
      default:
        return 'gray';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'Urgent':
        return <WarningIcon />;
      case 'Warning':
        return <WarningIcon />;
      case 'Information':
        return <InfoIcon />;
      case 'Reminder':
        return <EventNoteIcon />;
      case 'Promotion':
        return <LocalOfferIcon />;
      case 'Event':
        return <CalendarTodayIcon />;
      case 'Policy Update':
        return <PolicyIcon />;
      case 'Holiday Notice':
        return <PublicIcon />;
      default:
        return <AnnouncementIcon />;
    }
  };

  return (
    <AnnouncementBannerWrapper>
      {announcement ? (
        <div
          style={{
            color: 'white',
            width: '70%',
            alignContent: 'flex-end',
          }}
          className="announcement"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: getTypeBackgroundColor(announcement?.type),
                padding: '5px 10px',
                borderRadius: '5px',
                display: 'inline-block',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                width: '20%',
                color: 'black',
              }}
            >
              {getTypeIcon(announcement?.type)}
              <Typography
                variant="caption"
                component="div"
                style={{ color: 'black', fontSize: '15px' }}
              >
                {announcement?.type}
              </Typography>
            </div>
            <small style={{ marginLeft: 'auto' }}>
              {new Date(announcement?.updatedAt).toLocaleString()}
            </small>
          </div>
          <h1 style={{ margin: 0, fontWeight: 'Bold' }}>{announcement?.title}</h1>
          <p>{announcement?.description}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <DurationContainer>
              <Typography
                variant="caption"
                component="div"
                style={{ color: 'white', textAlign: 'center' }}
              >
                {remainingTime}
              </Typography>
            </DurationContainer>
          </div>
        </div>
      ) : (
        <p>No announcement found.</p>
      )}
    </AnnouncementBannerWrapper>
  );
};

export default AnnouncementBanner;

// import React, { useEffect, useState } from 'react';
// import { getAnnouncementByClinicId } from '../../../api-services/announcement-ws';
// import useAuth from 'app/hooks/useAuth';
// import { styled, Box, Typography } from '@mui/material';
// import AnnouncementIcon from '@mui/icons-material/Announcement';
// import WarningIcon from '@mui/icons-material/Warning';
// import InfoIcon from '@mui/icons-material/Info';
// import EventNoteIcon from '@mui/icons-material/EventNote';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import PolicyIcon from '@mui/icons-material/Policy';
// import PublicIcon from '@mui/icons-material/Public';
// import announcementImage from '../announcement/announcement_banner.jpg';

// const AnnouncementBannerWrapper = styled(Box)(({ theme }) => ({
//   backgroundImage: `url(${announcementImage})`,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center',
//   padding: theme.spacing(2),
//   display: 'flex',
//   justifyContent: 'right',
//   alignItems: 'center',
//   height: '250px',
//   width: '100%',
//   marginBottom: '10px',
//   borderRadius: '10px',
// }));

// const AnnouncementContent = styled(Box)(({ theme }) => ({
//   textAlign: 'center',
//   color: 'white',
//   backgroundColor: 'rgba(0, 0, 0, 0.6)',
//   padding: theme.spacing(2),
//   borderRadius: theme.shape.borderRadius,
//   width: '100%',
//   maxWidth: '600px',
// }));

// const DurationContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: 'rgba(255, 255, 255, 0.2)',
//   borderRadius: '50%',
//   width: '70px',
//   height: '70px',
//   marginLeft: 'auto',
//   padding: theme.spacing(1),
// }));

// const AnnouncementBanner = ({ clinicId }) => {
//   const [announcement, setAnnouncement] = useState(null);
//   const [elapsedTime, setElapsedTime] = useState('');
//   const { user } = useAuth();

//   useEffect(() => {
//     const fetchAnnouncement = async () => {
//       try {
//         const response = await getAnnouncementByClinicId(user?.refClinicId, user?.authToken);
//         const latestAnnouncement = response.data.data.reverse()[0];
//         setAnnouncement(latestAnnouncement);
//         if (latestAnnouncement) {
//           startStopwatch(latestAnnouncement.duration);
//         }
//       } catch (error) {
//         console.error('There was an error fetching the announcement!', error);
//       }
//     };
//     fetchAnnouncement();
//   }, [user?.refClinicId, user?.authToken]);

//   const startStopwatch = (duration) => {
//     const startTime = new Date(duration).getTime();
//     setInterval(() => {
//       const now = new Date().getTime();
//       const elapsed = now - startTime;
//       const hours = Math.floor((elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//       const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
//       const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
//       setElapsedTime(`${hours}:${minutes}:${seconds}`);
//     }, 1000);
//   };

//   const getTypeBackgroundColor = (type) => {
//     switch (type) {
//       case 'Urgent':
//         return 'red';
//       case 'Warning':
//         return 'orange';
//       case 'Information':
//         return 'blue';
//       case 'Reminder':
//         return 'green';
//       case 'Promotion':
//         return 'purple';
//       case 'Event':
//         return 'teal';
//       case 'Policy Update':
//         return 'brown';
//       case 'Holiday Notice':
//         return 'yellow';
//       default:
//         return 'gray';
//     }
//   };

//   const getTypeIcon = (type) => {
//     switch (type) {
//       case 'Urgent':
//         return <WarningIcon />;
//       case 'Warning':
//         return <WarningIcon />;
//       case 'Information':
//         return <InfoIcon />;
//       case 'Reminder':
//         return <EventNoteIcon />;
//       case 'Promotion':
//         return <LocalOfferIcon />;
//       case 'Event':
//         return <CalendarTodayIcon />;
//       case 'Policy Update':
//         return <PolicyIcon />;
//       case 'Holiday Notice':
//         return <PublicIcon />;
//       default:
//         return <AnnouncementIcon />;
//     }
//   };

//   return (
//     <AnnouncementBannerWrapper>
//       {announcement ? (
//         <div
//           style={{
//             color: 'white',
//             width: '70%',
//             alignContent: 'flex-end',
//           }}
//           className="announcement"
//         >
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               flexDirection: 'row',
//               marginBottom: '20px',
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: getTypeBackgroundColor(announcement?.type),
//                 padding: '5px 10px',
//                 borderRadius: '5px',
//                 display: 'inline-block',
//                 marginTop: '10px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: '10px',
//                 width: '20%',
//                 color: 'black',
//               }}
//             >
//               {getTypeIcon(announcement?.type)}
//               <Typography
//                 variant="caption"
//                 component="div"
//                 style={{ color: 'black', fontSize: '15px' }}
//               >
//                 {announcement?.type}
//               </Typography>
//             </div>
//             {/* <h1 style={{ margin: 0, fontWeight: 'Bold' }}>{announcement?.title}</h1> */}
//             <small style={{ marginLeft: 'auto' }}>
//               {new Date(announcement?.updatedAt).toLocaleString()}
//             </small>
//           </div>
//           <h1 style={{ margin: 0, fontWeight: 'Bold' }}>{announcement?.title}</h1>
//           <p>{announcement?.description}</p>
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               flexDirection: 'row',
//             }}
//           >
//             <DurationContainer>
//               <Typography
//                 variant="caption"
//                 component="div"
//                 style={{ color: 'white', textAlign: 'center' }}
//               >
//                 {elapsedTime}
//               </Typography>
//             </DurationContainer>
//           </div>
//         </div>
//       ) : (
//         <p>No announcement found.</p>
//       )}
//     </AnnouncementBannerWrapper>
//   );
// };

// export default AnnouncementBanner;
