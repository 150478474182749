import { Box, Card, Grid, Icon, styled, Button, useTheme, Typography } from '@mui/material';
import { Small } from 'app/components/Typography';
import React, { useEffect, useState } from 'react';
import { getDoctorDetails } from 'app/api-services/doctor-ws';
import { getPatientsByClinicId } from 'app/api-services/patient-ws';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import DoctorForm from './Forms/DoctorForm';
import useAuth from 'app/hooks/useAuth';
import DoughnutChart from './shared/Doughnut';
import { bgcolors } from 'app/utils/utils';
import AgreementDialog from '../agreement/Agreement';
import { updateClinicDetails } from 'app/api-services/clinic-ws';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

const ClinicDashboard = () => {
  const { palette } = useTheme();
  const [patientsList, setPatientsList] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { user } = useAuth();
  const [profileImage, setProfileImage] = useState();
  const [agreeChecked, setAgreeChecked] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const pieChartData = [
    {
      value: patientsList.filter((patient) => patient.status === 'REFERRED').length,
      name: 'Referred Patients',
    },
    {
      value: patientsList.filter((patient) => patient.status === 'REPORT_PENDING').length,
      name: 'Report Pending',
    },
    {
      value: patientsList.filter((patient) => patient.status === 'COMPLETED').length,
      name: 'Completed',
    },
  ];
  const theme = useTheme();
  useEffect(() => {
    fetchPatientDetails();
    fetchDoctorDetails();
  }, []);
  const fetchPatientDetails = () => {
    getPatientsByClinicId(user?._id, user?.authToken)
      .then((res) => {
        setPatientsList(res.data.data);
        setIsLoading(false);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };
  const clinicRequest = {
    _id: user?._id,
    TCAgree: agreeChecked,
  };
  const updateTC = () => {
    updateClinicDetails(clinicRequest, user?.authToken)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDoctorDetails = () => {
    getDoctorDetails(user?._id, user?.authToken)
      .then((res) => {
        setDoctorsList(res.data.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  const cardList = [
    { name: 'Number of Doctor registered ', amount: doctorsList.length, icon: 'group' },
    {
      name: 'Total patients referred',
      amount: patientsList.length,
      icon: 'group',
    },
  ];

  return (
    <div
      style={{ display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'center' }}
    >
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        {cardList.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <StyledCard elevation={6}>
              <ContentBox>
                <Icon className="icon">{item.icon}</Icon>
                <Box ml="12px">
                  <Small>{item.name}</Small>
                  {typeof item.amount === 'string' ? (
                    <Heading>{item.amount}</Heading>
                  ) : (
                    <div>{item.amount}</div>
                  )}
                </Box>
              </ContentBox>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Box
                ml="12px"
                mr="10px"
                alignItems={'center'}
                flex={1}
                display={'flex'}
                flexDirection={'row'}
              >
                <Typography style={{ marginRight: '90px' }}>Want to add new Doctors?</Typography>
                <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                  <Icon
                    className="icon"
                    style={{ height: '40px', color: 'white', marginRight: '8px' }}
                  >
                    group_add
                  </Icon>{' '}
                  Add Doctor
                </Button>
                <Dialog onClose={handleCloseDialog} open={openDialog}>
                  <DialogTitle>Doctor Form</DialogTitle>
                  <DialogContent>
                    <DoctorForm
                      profileImage={profileImage}
                      setProfileImage={setProfileImage}
                      clinicId={user?._id}
                      token={user?.authToken}
                      handleCloseDialog={handleCloseDialog}
                    />
                  </DialogContent>
                </Dialog>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
        {!user?.TCAgree || user.TCAgree == false ? (
          <Grid item lg={4} md={4} sm={12} xs={12}>
            
              <AgreementDialog
                agreeChecked={agreeChecked}
                setAgreeChecked={setAgreeChecked}
                updateTC={updateTC}
              />
          
          </Grid>
        ) : null}

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
            <Title>Status Summary</Title>
            {/* <SubTitle>Last 30 days</SubTitle> */}
            <DoughnutChart
              data={pieChartData}
              height="350px"
              color={[bgcolors.REFERRED, bgcolors.REPORT_PENDING, bgcolors.COMPLETED]}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClinicDashboard;

// // import React from 'react';

// // const ClinicDashboard = () => {
// //   return <label>Clinic Dashboard</label>;
// // };

// // export default ClinicDashboard;
