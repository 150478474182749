import React, { useState } from 'react';
import DoctorProfile from './doctorProfile';
import useAuth from 'app/hooks/useAuth';
import AdminProfile from './adminProfile';
import ClinicProfile from './clinicProfile';
import NotFound from '../sessions/NotFound';
import { getSelectedDoctorDetails } from 'app/api-services/doctor-ws';
import { Alert, Snackbar } from '@mui/material';
import { getClinicDetails } from 'app/api-services/clinic-ws';
import { getSelectedAdminDetails } from 'app/api-services/admin-ws';
import Loader from 'app/components/Loader';

const ProfilePage = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState(user);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const handleCloseDialog = () => {
    getUserDetails();
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const getUserDetails = () => {
    setLoading(true);
    if (user?.role === 'DOCTOR') {
      getSelectedDoctorDetails(user?._id, user?.authToken)
        .then((res) => {
          setUserDetails(res.data.doctorData);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    } else if (user?.role === 'CLINIC') {
      getClinicDetails(user?._id, user?.authToken)
        .then((res) => {
          setLoading(false);
          setUserDetails(res.data.Data);
        })
        .catch((err) => {
          setLoading(false);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    } else if (user?.role === 'ADMIN') {
      getSelectedAdminDetails(user?._id, user?.authToken)
        .then((res) => {
          setLoading(false);
          setUserDetails(res.data.Data);
        })
        .catch((err) => {
          setLoading(false);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    }
  };
  if (loading) {
    return <Loader loading={loading} />;
  }

  if (user?.role === 'ADMIN') {
    return (
      <div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={snackBarType}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
        <AdminProfile
          userDetails={userDetails}
          token={user?.authToken}
          openDialog={openDialog}
          handleDialogClose={handleCloseDialog}
          handleDialogOpen={handleOpenDialog}
        />
      </div>
    );
  } else if (user?.role === 'CLINIC') {
    return (
      <ClinicProfile
        userDetails={userDetails}
        token={user?.authToken}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleOpenDialog={handleOpenDialog}
      />
    );
  } else if (user?.role === 'DOCTOR') {
    return (
      <DoctorProfile
        userDetails={userDetails}
        doctorId={user?._id}
        openDialog={openDialog}
        token={user?.authToken}
        handleDialogClose={handleCloseDialog}
        handleDialogOpen={handleOpenDialog}
      />
    );
  } else {
    return <NotFound />;
  }
};

export default ProfilePage;
