import React from 'react';
import AnnouncementTable from './AnnouncementTable';

function ClinicAnnouncement() {
  return (
    <div>
      <AnnouncementTable />
    </div>
  );
}

export default ClinicAnnouncement;
