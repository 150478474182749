import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomizedDialogs from '../models/Dpatient';
import moment from 'moment';
import { bgcolors } from 'app/utils/utils';
import PatientReportDisplay from '../shared/PatientReportDisplay';
import { getAllPatientReport } from 'app/api-services/patientReport-ws';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600, // Adjust the minimum width as needed
  overflowX: 'auto',
});

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  fontWeight: '400',
  height: 15,
  color: 'white',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));

const DpatientTable = ({ token, fetchPatientDetails, patientsList }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [snackBarType, setSnackBarType] = useState('success');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [alreadyUploadedImages, setAlreadyUploadedImages] = useState([]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fetchAlreadyUploadedImages = async (patientReportFolder) => {
    getAllPatientReport(patientReportFolder, token)
      .then((res) => {
        setAlreadyUploadedImages(res.data.data);
      })
      .catch((err) => {
        setAlreadyUploadedImages([]);
      });
  };

  const getColorForStatus = (status) => {
    return bgcolors[status] || '#000'; // Default to black if the status is not recognized
  };

  function showSnackBar() {
    setOpen(true);
  }

  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Referred Date</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Status</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Patient Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patientsList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((patient, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
              >
                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                <TableCell align="left">
                  {patient?.patientName?.firstName?.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                  {patient?.patientName?.lastName?.replace(/^\w/, (c) => c.toUpperCase())}
                </TableCell>
                <TableCell align="center">{(patient?.createdDate).split('T')[0]}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <PatientReportDisplay
                    showSnackBar={showSnackBar}
                    setSnackBarType={setSnackBarType}
                    setSnackBarMessage={setSnackBarMessage}
                    patientId={patient?._id}
                    patient={patient}
                    patientStatus={patient?.status}
                    alreadyUploadedImages={alreadyUploadedImages}
                    fetchAlreadyUploadedImages={fetchAlreadyUploadedImages}
                  />
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <CustomizedDialogs
                    token={token}
                    fetchPatientDetails={fetchPatientDetails}
                    patient={patient}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={patientsList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default DpatientTable;
