import React, { useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from './DDI Logo.jpg';
import backgroundImage from '../../views/profile/bgblue.jpeg';
import OPG from '../../Images/OPG.jpg';
import CEPH from '../../Images/CEPH.jpg';
import TMJ from '../../Images/TMJ.jpg';
import TRUELATERAL from '../../Images/TLS.jpg';
import PNS from '../../Images/PNS.jpg';
import PAVIEW from '../../Images/PA.jpg';
import REVERSE from '../../Images/RT.jpg';
import AP from '../../Images/AP.jpg';
import JAW from '../../Images/JAW.jpg';
import PREIMPLANT from '../../Images/PREIMPLANT.jpg';
import ENDODONTIC from '../../Images/ENDO.jpg';
import TMJ3D from '../../Images/TMJ3D.png';
import PERIO from '../../Images/PERIO.jpg';
import POSTIMPLANT from '../../Images/POSTIMPLANT.jpg';
import MANDIBLE from '../../Images/MANDIBLE MAXILLA.jpg';
import MACHINE from '../../Images/machine.jpg';
import { color } from 'echarts';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: 'linear-gradient(180deg, #BBA5B5, #9a7991)',
    // backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover', // Adjust as needed
    backgroundRepeat: 'no-repeat', // Adjust as needed
    width: '100%',
  },
  container: {
    marginTop: '5px',
    marginBottom: '50px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    color: '#fff',
  },
  logo: {
    borderRadius: '50%',
    maxHeight: '100px',
    maxWidth: '100px',
    margin: '10px',
  },
  timingCard: {
    background: 'linear-gradient(180deg, #BBA5B5, #BBA5B5)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginTop: '10px',
  },
  timingTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: 'white',
  },
  timingDetails: {
    fontSize: '1.2rem',
    color: 'white',
  },
  addressCard: {
    background: 'linear-gradient(180deg, #BBA5B5, #BBA5B5)',
    // backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginTop: '10px',
  },
  addressTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: 'white',
  },
  addressDetails: {
    fontSize: '1.2rem',
    color: 'white',
  },
  carousel: {
    border: '2px solid #333', // Customize the border style as needed
    padding: '10px',
    borderRadius: '20px',
    height: '400px',
    display: 'flex',
    objectFit: 'contain',
    alignItems: 'center',
  },
  img: {
    borderRadius: '10px',
    width: '600px',
    height: '400px',
    objectFit: 'contain',
  },
}));

const ClinicInfo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmOrXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: '#913B58' }} position="static">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={logo}
                  alt="Logo"
                  className={classes.logo}
                  style={{ maxWidth: isSmOrXs ? '50px' : '100px' }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                className={classes.title}
                align="center"
                style={{ fontSize: isSmOrXs ? '1rem' : '2rem' }}
              >
                DENTAL DIGITAL IMAGING
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div style={{ textAlign: 'right' }}>
                <Typography
                  variant={isSmOrXs ? 'h6' : 'h5'}
                  style={{ fontSize: isSmOrXs ? '.75rem' : '1.5rem' }}
                >
                  Dr. Shivaprasad S
                </Typography>
                <Typography variant="h6" style={{ fontSize: isSmOrXs ? '.5rem' : '1rem' }}>
                  BDS,MDS
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Container className={classes.container}>
        <Card style={{ background: 'linear-gradient(180deg, #BBA5B5, #BBA5B5)' }}>
          <CardContent style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}>
            {isSmallScreen ? (
              <>
                <img src={MACHINE} alt="Machine" />
                <div>
                  <Typography style={{ fontSize: '2rem', fontWeight: 'bold', color: 'white' }}>
                    ABOUT US
                  </Typography>
                  <p style={{ fontSize: '1rem', color: 'white' }}>
                    Our centre was established on 14th May 2014. We cater to the needs of people in
                    and around Davangere. Our centre is the proud owner of Planmeca ProMax 3D
                    Classic machine and is certified by AERB. We follow all the rules governed by
                    AERB. The centre has established norms of cleanness and follows all protocols of
                    post-Covid.{' '}
                  </p>
                  <p style={{ fontSize: '1rem', color: 'white' }}>
                    {' '}
                    We have an expert consultant Maxillo-facial radiologist, who has 25 years of
                    teaching experience as a professor at Bapuji Dental College & Hospital,
                    Davangere. He has conducted 10 hands-on course workshops on CBCT and delivered
                    more than 30 lectures on CBCT.{' '}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Typography style={{ fontSize: '2rem', fontWeight: 'bold', color: 'white' }}>
                    ABOUT US
                  </Typography>
                  <p style={{ fontSize: '1rem', color: 'white' }}>
                    Our centre was established on 14th May 2014. We cater to the needs of people in
                    and around Davangere. Our centre is the proud owner of Planmeca ProMax 3D
                    Classic machine and is certified by AERB. We follow all the rules governed by
                    AERB. The centre has established norms of cleanness and follows all protocols of
                    post-Covid.{' '}
                  </p>
                  <p style={{ fontSize: '1rem', color: 'white' }}>
                    {' '}
                    We have an expert consultant Maxillo-facial radiologist, who has 25 years of
                    teaching experience as a professor at Bapuji Dental College & Hospital,
                    Davangere. He has conducted 10 hands-on course workshops on CBCT and delivered
                    more than 30 lectures on CBCT.{' '}
                  </p>
                </div>
                <img src={MACHINE} alt="Machine" />
              </>
            )}
          </CardContent>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <div>
              <h2 style={{ color: 'white' }}>2D images</h2>
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={2000}
                className={classes.carousel}
              >
                <div>
                  <img className={classes.img} src={OPG} alt="Image 1" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    ORTHOPANTOMOGRAPHY
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={CEPH} alt="Image 2" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    CEPHALOGRAM LARTERAL/FRONTAL
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={TRUELATERAL} alt="Image 3" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    TRUE LATERAL SKULL
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={PAVIEW} alt="Image 4" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    P A VIEW-SKULL/MANDIBLE
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={PNS} alt="Image 5" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    PNS VIEW
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={REVERSE} alt="Image 5" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    REVERSE TOWENS VIEW
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={TMJ} alt="Image 6" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    TMJ VIEWS/LEFT/RIGHT
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={AP} alt="Image 5" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    A P VIEW
                  </p>
                </div>
              </Carousel>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <div>
              <h2 style={{ color: 'white' }}>3D Images</h2>
              <Carousel
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={2500}
                className={classes.carousel}
              >
                <div>
                  <img
                    className={classes.img}
                    src={PREIMPLANT}
                    alt="Image 1"
                    style={{ height: '400px' }}
                  />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    PRE IMPLANT
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={POSTIMPLANT} alt="Image 2" />
                  <p
                    className="legend"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    POST IMPLANT
                  </p>
                </div>
                <div>
                  <img className={classes.img} src={ENDODONTIC} alt="Image 2" />
                  <p className="legend">ENDODONTICS</p>
                </div>
                <div>
                  <img className={classes.img} src={TMJ3D} alt="Image 2" />
                  <p className="legend">3d IMPACTED TEETH</p>
                </div>
                <div>
                  <img className={classes.img} src={TMJ3D} alt="Image 2" />
                  <p className="legend">TMJ-R SIDE/L SIDE</p>
                </div>
                <div>
                  <img className={classes.img} src={JAW} alt="Image 2" />
                  <p className="legend">JAW LESIONS</p>
                </div>
                <div>
                  <img className={classes.img} src={PERIO} alt="Image 2" />
                  <p className="legend">PERIODONTICS</p>
                </div>
              </Carousel>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Card className={classes.timingCard}>
              <CardContent>
                <Typography className={classes.timingTitle}>Timings</Typography>
                <Divider />
                <Typography className={classes.timingDetails}>
                  Monday-Saturday: 10:00 a.m. to 9:00 p.m.
                </Typography>
                <Typography className={classes.timingDetails} style={{ color: 'red' }}>
                  No Lunch Break
                </Typography>
                <Typography className={classes.timingDetails}>
                  Sunday: 11:00 a.m. to 2:00 p.m.
                </Typography>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '4px',
                  }}
                >
                  <PhoneIcon style={{ marginRight: '8px', color: 'white' }} />
                  <Typography className={classes.timingDetails}> +91 9448053148</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '4px',
                  }}
                >
                  <EmailIcon style={{ marginRight: '8px', color: 'white' }} />{' '}
                  <Typography className={classes.timingDetails}>
                    dentaldigitalimagingdvg@gmail.com
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card className={classes.addressCard}>
              <CardContent>
                <Typography className={classes.addressTitle}>Our Address/ನಮ್ಮ ವಿಳಾಸ:</Typography>
                <Divider />
                <Typography className={classes.addressDetails}>
                  Ganesha Complex, 1st Floor, Dental College Road,
                </Typography>
                <Typography className={classes.addressDetails}>
                  Opp. to Dental College, M.C.C 'B' Block, Davangere - 4
                </Typography>
                <Typography className={classes.addressDetails}>
                  ಗಣೇಶ ಕಮ್ಪ್ಲೆಕ್ಸ, 1ನೇ ಹಂತ, ಡೆಂಟಲ್ ಕಾಲೇಜು ರಸ್ತೆ,
                </Typography>
                <Typography className={classes.addressDetails}>
                  ಡೆಂಟಲ್ ಕಾಲೇಜಿನ ವಿರುದ್ಧ, ಎಮ್.ಸಿ.ಸಿ 'ಬಿ' ಬ್ಲಾಕ್, ದಾವಣಗೆರೆ - 4
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ClinicInfo;
