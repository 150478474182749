import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Span } from 'app/components/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  addImageToList,
  addimageOption,
  edit2DImageOption,
  edit3DImageOption,
  getImageOptionsList,
} from 'app/api-services/image-ws';
import { ValidatorForm } from 'react-material-ui-form-validator';
import useAuth from 'app/hooks/useAuth';

const ImageOptionForm = ({ disableName, is2D, handleCloseDialog, fetchImageOptions, image }) => {
  const [imageName, setImageName] = useState(image?.name);

  const [hasChanges, setHasChanges] = useState();
  const [sms, setSms] = useState(image?.sms);
  const [cost, setCost] = useState(image?.serviceCost);
  const { user } = useAuth();
  console.log('is2d', is2D);
  //add images
  const image2DRequest = {
    clinicId: user?._id,
    newDigitalImages2D: {
      name: imageName,
      sms: sms,
      serviceCost: cost,
    },
  };

  const add2DImages = () => {
    addImageToList(image2DRequest)
      .then((res) => {
        handleCloseDialog();
        fetchImageOptions();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };
  const image3DRequest = {
    clinicId: user?._id,
    newDigitalImages3D: {
      name: imageName,
      sms: sms,
      serviceCost: cost,
    },
  };
  const add3DImages = () => {
    addImageToList(image3DRequest)
      .then((res) => {
        handleCloseDialog();
        fetchImageOptions();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };
  //edit images
  const edit2DImage = () => {
    const edit2dRequest = {
      clinicId: user?._id,
      name: imageName,
      sms: sms,
      serviceCost: cost,
    };
    edit2DImageOption(edit2dRequest)
      .then((res) => {
        handleCloseDialog();
        fetchImageOptions();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };
  const edit3DImage = () => {
    const edit3dRequest = {
      clinicId: user?._id,
      name: imageName,
      sms: sms,
      serviceCost: cost,
    };
    edit3DImageOption(edit3dRequest)
      .then((res) => {
        handleCloseDialog();
        fetchImageOptions();
      })
      .catch((err) => {
        console.log(err);
        handleCloseDialog();
      });
  };

  const handleSubmit = () => {
    if (is2D) {
      return disableName ? edit2DImage() : add2DImages();
    } else {
      return disableName ? edit3DImage() : add3DImages();
    }
  };
  return (
    <div>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(err) => {
          console.log(err);
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={2}>
          <Grid item xs={6}>
            <TextField
              type="text"
              disabled={disableName}
              name="Image Name"
              id="standard-basic"
              value={imageName || ''}
              onChange={(val) => {
                setImageName(val.target.value);
              }}
              errorMessages={['this field is required']}
              label="Image Name"
              validators={['required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text" // Use "text" type for the phone number
              name="sms"
              label="SMS Name"
              value={sms || ''}
              onChange={(val) => {
                // Ensure that the entered value is numeric
                // Remove non-numeric characters
                setSms(val.target.value);
              }}
              validators={['required', 'matchRegexp:^\\d{5}$']}
              errorMessages={['This field is required', 'Phone number must be 5 digits']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number" // Use "text" type for the phone number
              name="serviceCost"
              label="Service Cost "
              value={cost || ''}
              onChange={(val) => {
                // Ensure that the entered value is numeric
                // Remove non-numeric characters
                setCost(val.target.value);
              }}
              validators={['required']} // Validate for 10 digits
              errorMessages={['This field is required']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>

          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {image ? 'Save Image' : 'Add Image'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default ImageOptionForm;
