import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Avatar,
  Grid,
  Box,
  Paper,
  Divider,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClinicForm from '../dashboard/Forms/ClinicForm';
import { makeStyles } from '@mui/styles';
import backgroundImage from './bgblue.jpeg';

const useStyles = makeStyles((theme) => ({
  mainCard: {
    borderRadius: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    width: '100%',
    // background: 'red',
  },
  editBtn: { alignSelf: 'center' },

  [theme.breakpoints.down('sm')]: {
    mainCard: {
      flexDirection: 'column', // Display image above text
      width: '90%',
      margin: '5px auto',
    },
    cardContent: {
      flexDirection: 'column',
      // Display the image above the text
    },
    box: {
      flexDirection: 'column',
    },
    paper: {
      padding: '1rem',
    },
    editBtn: {
      justifyContent: 'center',
    },
    clinName: {
      marginTop: '100px',
    },
  },
}));

const ClinicProfile = ({ userDetails, openDialog, handleCloseDialog, handleOpenDialog, token }) => {
  const [clinicLogo, setClinicLogo] = useState(userDetails?.clinicLogo);
  const { clinicName, clinicPhone, email, ownerName, address } = userDetails;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        // background: '#1A2038',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // Adjust as needed
        backgroundRepeat: 'no-repeat', // Adjust as needed
        width: '100%',
      }}
    >
      <Grid item xs={12} sm={8}>
        <Card className={classes.mainCard} variant="outlined">
          <Typography
            variant="h3"
            sx={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Geogria' }}
          >
            PROFILE
          </Typography>
          <CardContent className={classes.cardContent}>
            <Box
              className={classes.box}
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  flex: 1,
                  borderRadius: '16px',
                  marginRight: '1rem',
                  justifyContent: 'Center',
                  alignContent: 'center',
                  // backgroundColor: 'red',
                  maxHeight: '350px',
                  maxWidth: '400px',
                }}
              >
                <CardContent style={{}}>
                  <Avatar
                    className={classes.img}
                    alt="Doctor"
                    placeholder="No Profile Pic"
                    src={`data:image/jpeg;base64,${clinicLogo}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '16px',
                      justifyContent: 'center',
                      display: 'flex',
                      objectFit: 'contain',
                    }}
                  />
                </CardContent>
              </Card>
              <Paper
                className={classes.paper}
                sx={{
                  color: 'black',
                  padding: '2rem',
                  flex: 1,
                  borderRadius: '16px',
                  elevation: '0',
                  boxShadow: 'none',
                }}
              >
                <Typography variant="h4" style={{ marginTop: '-20px', marginBottom: '40px' }}>
                  {clinicName}
                </Typography>

                <Typography
                  className="clinName"
                  variant="body1"
                  sx={{ display: 'flex', alignItems: 'center', margin: '4px' }}
                >
                  {` ${ownerName?.firstName} ${ownerName?.lastName}`}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    margin: '4px',
                    alignItems: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <EmailIcon style={{ marginRight: '8px' }} /> {email}
                </Typography>
                <Divider style={{ margin: '8px', width: '80%' }} />
                <Typography
                  variant="body1"
                  sx={{ display: 'flex', alignItems: 'center', margin: '4px' }}
                >
                  <PhoneIcon style={{ marginRight: '8px' }} /> {clinicPhone?.cell}
                </Typography>
                <Divider style={{ margin: '8px', width: '80%' }} />
                <div
                  style={{
                    marginTop: '1rem',
                    borderRadius: '8px',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ display: 'flex', alignItems: 'center', margin: '1rem' }}
                  >
                    <LocationOnIcon style={{ marginRight: '8px' }} />
                    {`${address?.buildingNo},`}
                    <br />
                    {`${address?.locality}, ${address?.street}, `}
                    <br />
                    {`${address?.district},`} <br />
                    {`${address?.state} - ${address?.pincode}`}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: '1rem',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    className={classes.editBtn}
                    variant="contained"
                    color="primary"
                    justifyContent="cntre"
                    onClick={handleOpenDialog}
                  >
                    Edit Profile
                  </Button>
                </div>
              </Paper>
              <Dialog onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle>Profile Details</DialogTitle>
                <DialogContent>
                  <ClinicForm
                    token={token}
                    setClinicLogoe={setClinicLogo}
                    clinicLogo={clinicLogo}
                    clinic={userDetails}
                    handleCloseDialog={handleCloseDialog}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ClinicProfile;
