// import React, { useEffect, useState } from 'react';
// import QRCode from 'react-qr-code';
// import { checkAuthStatus, generateQR } from 'app/api-services/whatsapp-ws';
// import {
//   Button,
//   Snackbar,
//   Alert,
//   CircularProgress,
//   Box,
//   Typography,
//   Grid,
//   Container,
// } from '@mui/material';
// import useAuth from 'app/hooks/useAuth';
// import recipt from './recipt.jpg';
// import { useNavigate } from 'react-router-dom';
// import { logoutWhatsApp } from 'app/api-services/whatsapp-ws';

// function WhatsApp() {
//   const [open, setOpen] = useState(false);
//   const [snackBarMessage, setSnackBarMessage] = useState('');
//   const [snackBarType, setSnackBarType] = useState('success');
//   const [qrData, setQrData] = useState('');
//   const [blur, setBlur] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const { user } = useAuth();
//   const [errorOpen, setErrorOpen] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     document.title = 'WhatsApp QR Code Generator and Management';

//     fetchAuthStatus();
//     if (!blur) {
//       const timer = setTimeout(() => {
//         setBlur(true);
//       }, 30000);
//       return () => clearTimeout(timer);
//     }
//   }, [blur]);

//   const fetchQR = async () => {
//     setBlur(false);
//     setLoading(true);
//     try {
//       const res = await generateQR();
//       console.log('qr', res);
//       setQrData(res.data);
//       setSnackBarMessage('QR Code generated successfully!');
//       setSnackBarType('success');
//     } catch (err) {
//       console.error('Error generating QR:', err);
//       setSnackBarMessage('Failed to generate QR Code. Please try again.');
//       setSnackBarType('error');
//     } finally {
//       setLoading(false);
//       setOpen(true);
//     }
//   };

//   const fetchAuthStatus = async () => {
//     try {
//       const res = await checkAuthStatus();
//       if (res.data === 'CONNECTED') {
//         setIsLoggedIn(true);
//         setSnackBarMessage('Already logged in');
//         setSnackBarType('success');
//       } else {
//         setIsLoggedIn(false);
//         setSnackBarMessage('Please log in again');
//         setSnackBarType('error');
//       }
//     } catch (err) {
//       console.error('Error checking auth status:', err);
//       setSnackBarMessage('Error checking authentication status. Please try again.');
//       setSnackBarType('error');
//     } finally {
//       setOpen(true);
//     }
//   };
//   const navigate = useNavigate();

//   const handleClose = (_, reason) => {
//     if (reason === 'clickaway') return;
//     setOpen(false);
//   };

//   const handleLogoutWhatsApp = async () => {
//     try {
//       await logoutWhatsApp();
//       setErrorMessage('Logged out of WhatsApp successfully.');
//       setErrorOpen(true);
//     } catch (error) {
//       setErrorMessage('Failed to log out of WhatsApp.');
//       setErrorOpen(true);
//     }
//   };

//   return (
//     <div
//       style={{ paddingTop: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
//     >
//       <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity={snackBarType} variant="filled">
//           {snackBarMessage}
//         </Alert>
//       </Snackbar>

//       {!isLoggedIn ? (
//         <Box
//           sx={{ display: 'flex', justifyContent: 'center', height: '30vh', position: 'relative' }}
//         >
//           <Box sx={{ position: 'relative', textAlign: 'center' }}>
//             <Box
//               sx={{
//                 position: 'absolute',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 filter: blur ? 'blur(4px)' : 'none',
//               }}
//             >
//               {qrData ? (
//                 <QRCode value={qrData} />
//               ) : (
//                 <Typography>QR Code will appear here</Typography>
//               )}
//             </Box>

//             {blur && (
//               <Button
//                 onClick={fetchQR}
//                 variant="contained"
//                 sx={{
//                   position: 'absolute',
//                   top: '50%',
//                   left: '50%',
//                   backgroundColor: '#007BFF',
//                   color: 'white',
//                   transform: 'translate(-50%, -50%)',
//                   zIndex: 1,
//                 }}
//                 disabled={loading}
//               >
//                 {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Generate QR'}
//               </Button>
//             )}
//           </Box>
//         </Box>
//       ) : (
//         <Container
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             minHeight: '100vh', // Make sure it takes the full height of the viewport
//           }}
//         >
//           <Grid
//             container
//             style={{
//               textAlign: 'center', // Center the text inside the Grid
//               justifyContent: 'center', // Center the grid items horizontally
//             }}
//           >
//             <Typography style={{ fontSize: '32px', marginBottom: '20px' }}>
//               You are already logged in, go ahead and send Receipts
//             </Typography>
//           </Grid>
//           <Button
//             onClick={() => {
//               navigate('/clinic/patientList');
//             }}
//             variant="outlined"
//             style={{ marginBottom: '20px' }}
//           >
//             Patient Section
//           </Button>

//           <Button
//             onClick={handleLogoutWhatsApp}
//             variant="outlined"
//             style={{ marginBottom: '20px' }}
//           >
//             Logout Whatsapp
//           </Button>
//           <Grid item>
//             <img
//               style={{ width: '500px', height: '400px', marginTop: '20px' }}
//               src={recipt}
//               alt="Recipt"
//             />
//           </Grid>
//         </Container>
//       )}
//     </div>
//   );
// }

// export default WhatsApp;
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { checkAuthStatus, generateQR } from 'app/api-services/whatsapp-ws';
import {
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import recipt from './recipt.jpg';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { logoutWhatsApp } from 'app/api-services/whatsapp-ws';

function WhatsApp() {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [qrData, setQrData] = useState('');
  const [blur, setBlur] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Set the document title
    document.title = 'WhatsApp QR Code Generator and Management';

    fetchAuthStatus();
    if (!blur) {
      const timer = setTimeout(() => {
        setBlur(true);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [blur]);

  const fetchQR = async () => {
    setBlur(false);
    setLoading(true);
    try {
      const res = await generateQR();
      console.log('qr', res);
      setQrData(res.data);
      setSnackBarMessage('QR Code generated successfully!');
      setSnackBarType('success');
    } catch (err) {
      console.error('Error generating QR:', err);
      setSnackBarMessage('Failed to generate QR Code. Please try again.');
      setSnackBarType('error');
    } finally {
      setLoading(false);
      setOpen(true);
    }
  };

  const fetchAuthStatus = async () => {
    try {
      const res = await checkAuthStatus();
      if (res.data === 'CONNECTED') {
        setIsLoggedIn(true);
        setSnackBarMessage('Already logged in');
        setSnackBarType('success');
      } else {
        setIsLoggedIn(false);
        setSnackBarMessage('Please log in again');
        setSnackBarType('error');
      }
    } catch (err) {
      console.error('Error checking auth status:', err);
      setSnackBarMessage('Error checking authentication status. Please try again.');
      setSnackBarType('error');
    } finally {
      setOpen(true);
    }
  };

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleLogoutWhatsApp = async () => {
    await logoutWhatsApp()
      .then((res) => {
        fetchAuthStatus();
        setErrorMessage(res.data);
        setErrorOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage('Failed to log out of WhatsApp.');
        setErrorOpen(true);
      });
  };

  return (
    <div
      style={{ paddingTop: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarType} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>

      {!isLoggedIn ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', height: '30vh', position: 'relative' }}
        >
          <Box sx={{ position: 'relative', textAlign: 'center' }}>
            {/* Title Above the QR Code and Button */}
            <Typography variant="h4" gutterBottom>
              Generate Your WhatsApp QR Code
            </Typography>

            <Box
              sx={{
                position: 'relative',
                top: '70%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                filter: blur ? 'blur(4px)' : 'none',
              }}
            >
              {qrData ? (
                <QRCode value={qrData} />
              ) : (
                <Typography>QR Code will appear here</Typography>
              )}
            </Box>

            {blur && (
              <Button
                onClick={fetchQR}
                variant="contained"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  backgroundColor: '#007BFF',
                  color: 'white',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Generate QR'}
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh', // Make sure it takes the full height of the viewport
          }}
        >
          <Grid
            container
            style={{
              textAlign: 'center', // Center the text inside the Grid
              justifyContent: 'center', // Center the grid items horizontally
            }}
          >
            <Typography style={{ fontSize: '32px', marginBottom: '20px' }}>
              You are already logged in, go ahead and send Receipts
            </Typography>
          </Grid>
          <Button
            onClick={() => {
              navigate('/clinic/patientList');
            }}
            variant="outlined"
            style={{ marginBottom: '20px' }}
          >
            Patient Section
          </Button>

          {/* <Button
            startIcon={<WhatsAppIcon />}
            onClick={handleLogoutWhatsApp}
            variant="outlined"
            style={{ marginBottom: '20px' }}
          >
            Logout of Whatsapp
          </Button> */}
          <Grid item>
            <img
              style={{ width: '500px', height: '400px', marginTop: '20px' }}
              src={recipt}
              alt="Receipt"
            />
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default WhatsApp;
